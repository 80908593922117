import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useDocTitle = (title: string) => {
  const location = useLocation();

  useEffect(() => {
    document.title = title;
  }, [location.pathname]);
};

export default useDocTitle;
