import { useEffect, useState } from 'react';

import { getPosts } from '@/services/instagram/api';
import { INSTAGRAM_STORAGE_KEY } from '@/settings/constants';

const LIMIT_CONTENT = 6;

interface InstagramPost {
  id: string;
  media_type: string;
  media_url: string;
  permalink: string;
}

const useInstagramPosts = () => {
  const [posts, setPosts] = useState<InstagramPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const instagramToken = import.meta.env.VITE_INSTAGRAM_TOKEN;
    const localStorageToken = localStorage.getItem(INSTAGRAM_STORAGE_KEY);
    const token = localStorageToken ?? instagramToken;
    const abortController = new AbortController();

    if (!localStorageToken) {
      localStorage.setItem(INSTAGRAM_STORAGE_KEY, token);
    }

    async function fetchPosts() {
      try {
        const { postList } = await getPosts(token ?? '');
        let nextPage = postList.paging.next;
        const data: InstagramPost[] = postList.data.filter(
          (post: InstagramPost) => post.media_type !== 'VIDEO',
        );

        let requestLimit = 3;

        while (data.length < LIMIT_CONTENT && requestLimit > 0) {
          const { postList: additionalPostList } = await getPosts(token ?? '', {
            after: nextPage.split('&after=')[1],
          });

          data.push(
            ...additionalPostList.data.filter(
              (post: InstagramPost) => post.media_type !== 'VIDEO',
            ),
          );

          nextPage = additionalPostList.paging.next;
          requestLimit -= 1;
        }

        setPosts(data.slice(0, LIMIT_CONTENT));
      } finally {
        setLoading(false);
      }
    }

    fetchPosts().catch((err) => {
      if (err instanceof Error) {
        setError(err);
      }

      setError(new Error('An error occurred while fetching posts'));
    });

    return () => {
      abortController.abort();
    };
  }, []);

  return { posts, loading, error };
};

export default useInstagramPosts;
