import { type Dispatch, type SetStateAction } from 'react';

import './styles.scss';

interface OverlayProps {
  children: React.ReactNode;
  handleOpen: Dispatch<SetStateAction<boolean>>;
}

function Overlay({ children, handleOpen }: OverlayProps) {
  return (
    <section className="overlay">
      {children}
      <img
        src="/icons/close.svg"
        alt="close"
        className="overlay__close"
        onClick={() => {
          handleOpen(false);
        }}
      />
    </section>
  );
}

export default Overlay;
