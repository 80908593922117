import PageTitle from '@/components/atoms/PageTitle';
import { HeroTitleTypo } from '@/components/atoms/TitleTypo';
import Breadcrumb from '@/components/molecules/Breadcrumb';
import FaqCollapse from '@/components/organisms/FaqCollapse';
import { faqList } from '@/components/pages/Faq/data';
import { type PageProps } from '@/components/pages/types';
import AppTemplate from '@/components/templates/AppTemplate';
import useDocTitle from '@/hooks/useDocTitle';
import { i18n } from '@/translate/i18n';
import { v4 as uuidv4 } from 'uuid';

import './styles.scss';

const Faq = ({ title }: PageProps) => {
  useDocTitle(title);

  return (
    <AppTemplate>
      <PageTitle>
        <HeroTitleTypo color="white-900">
          {i18n.t('homePage.faq.title')}
        </HeroTitleTypo>
      </PageTitle>
      <div className="page-faq">
        <Breadcrumb />
        <div className="faq">
          {faqList.map((question) => (
            <FaqCollapse
              title={question.title}
              source={question.answer}
              key={uuidv4()}
            />
          ))}
        </div>
      </div>
    </AppTemplate>
  );
};

export default Faq;
