import { Link } from 'react-router-dom';

import CustomDropdown from '@/components/atoms/CustomDropdown';
import type { NavigationProps } from '@/components/molecules/Navigation/types';

import './styles.scss';

const Navigation = ({
  navLinks,
  isMenuOpen,
  setIsMenuOpen,
}: NavigationProps) => {
  return (
    <nav className={`navigation ${isMenuOpen ? 'active' : ''}`}>
      <img
        src="/icons/close.svg"
        alt="close"
        className="navigation__close-btn"
        onClick={() => {
          setIsMenuOpen();
        }}
      />
      <ul className="navigation__links">
        {navLinks
          .filter((navlink) => navlink?.name)
          .map((navlink) => (
            <li key={navlink.id} className="navigation__item">
              {navlink.listItems ? (
                <CustomDropdown
                  styleClass="navigation-dropdown"
                  options={navlink.listItems ?? []}
                  label={navlink.name}
                  mainPath={navlink.path}
                />
              ) : (
                <Link to={navlink.path as string} className="navigation__link">
                  {navlink.name}
                </Link>
              )}
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default Navigation;
