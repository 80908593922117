import parse from 'html-react-parser';

import PageTitle from '@/components/atoms/PageTitle';
import { HeroTitleTypo } from '@/components/atoms/TitleTypo';
import Typography from '@/components/atoms/Typography';
import Breadcrumb from '@/components/molecules/Breadcrumb';
import ServiceForm from '@/components/molecules/ServiceForm';
import BadgeList from '@/components/organisms/BadgeList';
import MediaWithText from '@/components/organisms/MediaWithText';
import SectionBulletText from '@/components/organisms/SectionBulletText';
import SectionServices from '@/components/organisms/SectionServices';
import {
  formPageInfo,
  pageContent,
  understandingFengShui,
  whatIsKi,
  whatToExpect,
} from '@/components/pages/FengShui/data';
import { servicesList } from '@/components/pages/Home/data';
import { type PageProps } from '@/components/pages/types';
import AppTemplate from '@/components/templates/AppTemplate';
import useDocTitle from '@/hooks/useDocTitle';
import { i18n } from '@/translate/i18n';
import { getPageId, scrollToAnchor } from '@/utils/helpers';

import './styles.scss';

const FengShui = ({ title }: PageProps) => {
  useDocTitle(title);

  return (
    <AppTemplate>
      <PageTitle>
        <HeroTitleTypo color="white-900">{pageContent.title}</HeroTitleTypo>
      </PageTitle>
      <div className="understanding-feng-shui__container">
        <Breadcrumb />
        <MediaWithText {...understandingFengShui} />
        {pageContent.understandingFengShui.appendix && (
          <Typography styleClass="understanding-feng-shui__appendix">
            {parse(pageContent.understandingFengShui.appendix)}
          </Typography>
        )}
      </div>
      <div className="what-is-ki__container">
        <MediaWithText {...whatIsKi} />
      </div>
      <div className="what-to-expect__container">
        <MediaWithText
          {...whatToExpect}
          handleClick={() => {
            scrollToAnchor('#form');
          }}
        />
      </div>
      <BadgeList
        title={pageContent.badgeList.title}
        badges={pageContent.badgeList.badges}
      />
      <SectionBulletText
        title={pageContent.bonusServices.title}
        bullets={pageContent.bonusServices.bonusList}
      />
      <ServiceForm pageInfo={formPageInfo} />
      <SectionServices
        title={i18n.t('services.alternativeTitle')}
        removeId={getPageId(servicesList, 'feng')}
      />
    </AppTemplate>
  );
};

export default FengShui;
