import { type NineStarPageData } from '@/components/pages/NineStar/types';
import {
  MEDIA_QUERIES,
  MEDIA_WITH_TEXT_PROPS_VALUES,
} from '@/settings/constants';
import { i18n } from '@/translate/i18n';
import { v4 as uuidv4 } from 'uuid';

export const pageContent: NineStarPageData = i18n.t('servicePages.nineStar', {
  returnObjects: true,
});

const isMobile = window.innerWidth < MEDIA_QUERIES.tablet;

export const thePowerOfNineStar = {
  titleText: pageContent.thePowerOfNineStar.title,
  iconPath: pageContent.thePowerOfNineStar.kanjiPath,
  textContent: isMobile
    ? pageContent.thePowerOfNineStar.description
    : `${pageContent.thePowerOfNineStar.description}<br /><br />${
        pageContent.thePowerOfNineStar.appendix ?? ''
      }`,
  mediaPath: pageContent.thePowerOfNineStar.mediaPath,
  mediaType: MEDIA_WITH_TEXT_PROPS_VALUES.type.image,
  mediaOrientation: MEDIA_WITH_TEXT_PROPS_VALUES.orientation.square,
  appendix: isMobile ? pageContent.thePowerOfNineStar.appendix : '',
};

export const signatureTabs = pageContent.signatureTabs.map((tab) => {
  const [name, content] = tab;

  return { name, content, id: uuidv4() };
});

export const whyNineStar = {
  partOne: {
    titleText: pageContent.whyNineStarPartOne.title,
    textContent: pageContent.whyNineStarPartOne.description,
    mediaPath: pageContent.whyNineStarPartOne.mediaPath,
    mediaType: MEDIA_WITH_TEXT_PROPS_VALUES.type.image,
  },
  partTwo: {
    textContent: pageContent.whyNineStarPartTwo.description,
    mediaPath: pageContent.whyNineStarPartTwo.mediaPath,
    mediaType: MEDIA_WITH_TEXT_PROPS_VALUES.type.image,
    mediaPosition: MEDIA_WITH_TEXT_PROPS_VALUES.position.left,
    ctaButton: pageContent.whyNineStarPartTwo.ctaButton,
    ctaButtonColor: MEDIA_WITH_TEXT_PROPS_VALUES.buttonColor.secondary,
    ctaButtonPosition: MEDIA_WITH_TEXT_PROPS_VALUES.buttonPosition.middle,
  },
};

export const formPageInfo = {
  formTitle: pageContent.formTitle,
  defaultService: i18n.t('servicePages.form.options.nineStar'),
};
