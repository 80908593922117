import CustomDropdown from '@/components/atoms/CustomDropdown';
import { useLanguage } from '@/context/LanguageContext';
import {
  I18N_STORAGE_KEY,
  LANGUAGES as languageOptions,
} from '@/settings/constants';

import './styles.scss';

const LanguageSelector = () => {
  const { language, setLanguage } = useLanguage();

  const handleSelect = (option: string) => {
    const newLanguage = languageOptions.find((lang) => lang.value === option);

    if (newLanguage) {
      localStorage.setItem(I18N_STORAGE_KEY, newLanguage.value);
      setLanguage(newLanguage);
      window.location.assign('/');

      return;
    }
    throw new Error('Language not found');
  };

  return (
    <CustomDropdown
      styleClass="language-selector"
      options={languageOptions}
      label={language.name}
      handleSelect={(option: string) => {
        handleSelect(option);
      }}
    />
  );
};

export default LanguageSelector;
