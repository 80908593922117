import { Video } from '@/components/atoms/Video';
import Accordion from '@/components/molecules/Accordion';

import './styles.scss';

interface FaqCollapseProps {
  source: string;
  title: string;
}

const FaqCollapse = ({ source, title }: FaqCollapseProps) => {
  return (
    <Accordion title={title}>
      <div className="faq-body">
        <Video source={source} />
      </div>
    </Accordion>
  );
};

export default FaqCollapse;
