import { type BaZiPageData } from '@/components/pages/BaZi/types';
import { i18n } from '@/translate/i18n';

export const pageContent: BaZiPageData = i18n.t('servicePages.BaZi', {
  returnObjects: true,
});

export const destinyCode = {
  titleText: pageContent.destinyCode.title,
  kanjiPath: pageContent.destinyCode.kanjiPath,
  textContent: pageContent.destinyCode.description,
  appendix: pageContent.destinyCode.appendix,
};

export const baziPrecision = {
  titleText: pageContent.baziPrecision.title,
  textContent: pageContent.baziPrecision.description,
  mediaPath: pageContent.baziPrecision.mediaPath,
  mediaType: 'video' as const,
  ctaButton: pageContent.baziPrecision.ctaButton,
  ctaButtonPosition: 'middle' as const,
};

export const formPageInfo = {
  formTitle: pageContent.formTitle,
  defaultService: i18n.t('servicePages.form.options.bazi'),
};
