import '@/App.scss';
import Home from '@/components/pages/Home';
import AppTemplate from '@/components/templates/AppTemplate';
import { i18n } from '@/translate/i18n';

const App = () => {
  const pageTitle = `${i18n.t('documentTitle.main')} - ${i18n.t(
    'documentTitle.home',
  )}`;

  return (
    <AppTemplate>
      <Home title={pageTitle} />
    </AppTemplate>
  );
};

export default App;
