import {
  countries,
  zones,
} from '@/translate/getCountryLanguage/timezoneMap.json';

type TimezoneMap = typeof countries;

const getCountryLanguage = () => {
  const timezoneCityToCountry: Partial<TimezoneMap> = {};

  Object.keys(zones).forEach((zone) => {
    const city = zone.split('/').pop() as keyof typeof countries;

    timezoneCityToCountry[city] =
      countries[
        zones[zone as keyof typeof zones].countries[0] as keyof typeof countries
      ];
  });

  const browserTimezone = Intl?.DateTimeFormat().resolvedOptions().timeZone;

  const userLocation = {
    region: '',
    city: '',
    country: '',
    timezone: browserTimezone,
  };

  const timezones = userLocation.timezone?.split('/');
  userLocation.region = timezones[0];
  userLocation.city = timezones.pop()!;
  userLocation.country =
    timezoneCityToCountry[userLocation.city as keyof typeof countries]!.abbr;

  const langMap = {
    BR: 'pt-BR',
    US: 'en-US',
  };

  const language = (userLocation.country as keyof typeof langMap) || 'BR';

  return langMap[language];
};

export default getCountryLanguage;
