import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

import Button from '@/components/atoms/Button';
import { SubtitleTypo } from '@/components/atoms/TitleTypo';
import Typography from '@/components/atoms/Typography';
import { i18n } from '@/translate/i18n';

import './styles.scss';

interface ServiceCardProps {
  title: string;
  description: string;
  icon: string;
  path: string;
  isCarousel?: boolean;
}

const ServiceCard = ({
  title,
  description,
  icon,
  path,
  isCarousel = true,
}: ServiceCardProps) => {
  return (
    <article
      className={`${
        isCarousel ? 'carousel-view' : 'grid-view'
      } services-card keen-slider__slide`}
    >
      <div className="services-title">
        <img className="services-kanji" src={icon} alt={title} />
        <SubtitleTypo>{title}</SubtitleTypo>
      </div>
      <Typography styleClass="services-card__text">
        {parse(description)}
      </Typography>
      <Link to={path} className="services-card__link">
        <Button>{i18n.t('services.ctaButton')}</Button>
      </Link>
    </article>
  );
};

export default ServiceCard;
