import Typography from '@/components/atoms/Typography';
import { type AnimalsCardProps } from '@/components/molecules/AnimalsCard/types';

import './styles.scss';

function AnimalsCard({
  isCarousel = true,
  minimalVersion,
  chineseAnimalsCards,
}: AnimalsCardProps) {
  const { title, icon, description } = chineseAnimalsCards;

  return (
    <article
      className={`${
        isCarousel ? 'carousel-view' : 'grid-view'
      } animal-card keen-slider__slide ${minimalVersion ? 'minimal' : ''}`}
    >
      <h2 className="animal-title">{title}</h2>
      <img className="animal-card__icon" src={icon} alt={`${title}-icon`} />
      <div className="description">
        <Typography>{description}</Typography>
      </div>
    </article>
  );
}

export default AnimalsCard;
