import { type IChingPageData } from '@/components/pages/IChing/types';
import { MEDIA_WITH_TEXT_PROPS_VALUES } from '@/settings/constants';
import { i18n } from '@/translate/i18n';

export const pageContent: IChingPageData = i18n.t('servicePages.iChing', {
  returnObjects: true,
});

export const understandingIChingData = {
  titleText: pageContent.understandingIChing.title,
  iconPath: pageContent.understandingIChing.kanjiPath,
  textContent: pageContent.understandingIChing.description,
  mediaPath: pageContent.understandingIChing.mediaPath,
  mediaType: MEDIA_WITH_TEXT_PROPS_VALUES.type.image,
};

export const howItHelpsData = {
  titleText: pageContent.howItHelps.title,
  textContent: pageContent.howItHelps.description,
  mediaPath: pageContent.howItHelps.mediaPath,
  mediaType: MEDIA_WITH_TEXT_PROPS_VALUES.type.video,
  mediaPosition: MEDIA_WITH_TEXT_PROPS_VALUES.position.left,
  ctaButton: pageContent.howItHelps.ctaButton,
  ctaButtonColor: MEDIA_WITH_TEXT_PROPS_VALUES.buttonColor.secondary,
  ctaButtonPosition: MEDIA_WITH_TEXT_PROPS_VALUES.buttonPosition.middle,
};

export const formPageInfo = {
  formTitle: pageContent.formTitle,
  defaultService: i18n.t('servicePages.form.options.iChing'),
};
