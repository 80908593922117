import { Link, useParams } from 'react-router-dom';

import ZodiacCard from '@/components/molecules/ZodiacCard';
import { type ZodiacCardData } from '@/components/molecules/ZodiacCard/types';
import { cardsData } from '@/components/organisms/ZodiacAnimalsPrediction/data';
import { i18n } from '@/translate/i18n';
import { toRouteParam } from '@/utils/formatters';

import './styles.scss';

interface ZodiacAnimalsPredictionProps {
  year: string;
}

const ZodiacAnimalsPrediction = ({ year }: ZodiacAnimalsPredictionProps) => {
  const { slug } = useParams();
  const articleSlug = toRouteParam(slug ?? '');

  return (
    <div className="zodiac-animals-prediction__container">
      {Array.isArray(cardsData) &&
        cardsData.map(([id, data]: [string, ZodiacCardData]) => {
          return (
            <Link
              key={id}
              to={`${toRouteParam(
                i18n.t('articles.title'),
                true,
              )}${articleSlug}/${year}${toRouteParam(data.title)}`}
            >
              <ZodiacCard zodiacAnimal={data} minimalVersion />
            </Link>
          );
        })}
    </div>
  );
};

export default ZodiacAnimalsPrediction;
