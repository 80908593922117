import { TitleTypo } from '@/components/atoms/TitleTypo';
import Typography from '@/components/atoms/Typography';
import Accordion from '@/components/molecules/Accordion';
import { faqList } from '@/components/pages/Home/data';
import { i18n } from '@/translate/i18n';
import { v4 as uuidv4 } from 'uuid';

import './styles.scss';

const SectionFaq = () => {
  return (
    <section className="home__faq">
      <div className="home__faq-container">
        <TitleTypo>{i18n.t('faq.title')}</TitleTypo>
        <div className="home__faq-content">
          {Array.isArray(faqList) &&
            faqList.map(({ title, answer }) => (
              <Accordion key={uuidv4()} title={title}>
                <Typography>{answer}</Typography>
              </Accordion>
            ))}
        </div>
      </div>
    </section>
  );
};

export default SectionFaq;
