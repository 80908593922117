import { MEDIA_QUERIES } from '@/settings/constants';

const isMobile = window.innerWidth < MEDIA_QUERIES.tablet;
const isDesktop = window.innerWidth >= MEDIA_QUERIES.desktop;

const smallScreen = {
  spacing: 20,
  slides: 1,
};

const mediumScreen = {
  spacing: 22,
  slides: 2,
};

const largeScreen = {
  spacing: 22,
  slides: 2,
};

let spaceBetweenCards;

if (isMobile) {
  spaceBetweenCards = smallScreen.spacing;
} else if (isDesktop) {
  spaceBetweenCards = largeScreen.spacing;
} else {
  spaceBetweenCards = mediumScreen.spacing;
}

export const carouselProps = {
  slidesPerView: {
    mobile: smallScreen.slides,
    tablet: mediumScreen.slides,
    desktop: largeScreen.slides,
  },
  spacing: spaceBetweenCards,
  indicatorType: 'dots' as const,
  indicatorColor: 'light' as const,
};
