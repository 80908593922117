import parse from 'html-react-parser';

import PageTitle from '@/components/atoms/PageTitle';
import { HeroTitleTypo } from '@/components/atoms/TitleTypo';
import Typography from '@/components/atoms/Typography';
import Breadcrumb from '@/components/molecules/Breadcrumb';
import ServiceForm from '@/components/molecules/ServiceForm';
import ServiceTitle from '@/components/molecules/ServiceTitle';
import MediaWithText from '@/components/organisms/MediaWithText';
import SectionServices from '@/components/organisms/SectionServices';
import SectionZodiac from '@/components/organisms/SectionZodiac';
import {
  baziPrecision,
  destinyCode,
  formPageInfo,
} from '@/components/pages/BaZi/data';
import { servicesList } from '@/components/pages/Home/data';
import { type PageProps } from '@/components/pages/types';
import AppTemplate from '@/components/templates/AppTemplate';
import useDocTitle from '@/hooks/useDocTitle';
import { i18n } from '@/translate/i18n';
import { getPageId, scrollToAnchor } from '@/utils/helpers';

import './styles.scss';

const BaZi = ({ title }: PageProps) => {
  useDocTitle(title);

  return (
    <AppTemplate>
      <PageTitle>
        <HeroTitleTypo color="white-900">
          {i18n.t('servicePages.BaZi.title')}
        </HeroTitleTypo>
      </PageTitle>
      <div className="page-bazi">
        <div className="destiny-code__container">
          <Breadcrumb />
          <ServiceTitle
            title={destinyCode.titleText ?? ''}
            iconPath={destinyCode.kanjiPath}
          />
          <Typography styleClass="text">
            {parse(destinyCode.textContent)}
          </Typography>
          <img
            src="/images/bazi/bazi-table-mobile.webp"
            alt="bazi-table"
            className="destiny-code__image"
          />
          {destinyCode.appendix && (
            <Typography styleClass="text">
              {parse(destinyCode.appendix)}
            </Typography>
          )}
        </div>
        <MediaWithText
          {...baziPrecision}
          handleClick={() => {
            scrollToAnchor('#form');
          }}
        />
        <SectionZodiac
          title={i18n.t('servicePages.BaZi.zodiacCarousel.title') ?? ''}
          type="carousel"
        />
        <ServiceForm pageInfo={formPageInfo} />
        <SectionServices
          title={i18n.t('services.alternativeTitle')}
          removeId={getPageId(servicesList, 'bazi')}
        />
      </div>
    </AppTemplate>
  );
};

export default BaZi;
