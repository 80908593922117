import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';

import PageTitle from '@/components/atoms/PageTitle';
import { HeroTitleTypo } from '@/components/atoms/TitleTypo';
import Typography from '@/components/atoms/Typography';
import Breadcrumb from '@/components/molecules/Breadcrumb';
import MediaWithText from '@/components/organisms/MediaWithText';
import SectionServices from '@/components/organisms/SectionServices';
import SectionZodiac from '@/components/organisms/SectionZodiac';
import { horoscope, pageContent } from '@/components/pages/ZodiacAnimals/data';
import { type PageProps } from '@/components/pages/types';
import AppTemplate from '@/components/templates/AppTemplate';
import useDocTitle from '@/hooks/useDocTitle';
import useScreenWidth from '@/hooks/useScreenWidth';
import { I18N_STORAGE_KEY } from '@/settings/constants';
import { i18n } from '@/translate/i18n';

import './styles.scss';

const ZodiacAnimals = ({ title }: PageProps) => {
  const { isMobile, isTablet } = useScreenWidth();
  const navigate = useNavigate();

  useDocTitle(title);

  const handleClick = () => {
    const articleLink = `/${
      window.localStorage.getItem(I18N_STORAGE_KEY) || 'pt-BR'
    }${pageContent.horoscope.ctaLink || ''}`;

    articleLink && navigate(articleLink);
  };

  return (
    <AppTemplate>
      <PageTitle>
        <HeroTitleTypo color="white-900">{pageContent.title}</HeroTitleTypo>
      </PageTitle>
      <Breadcrumb />
      <div className="zodiac-animals__container">
        <Typography styleClass="zodiac-animals__description">
          {parse(pageContent.content)}
        </Typography>
        <SectionZodiac
          type={isMobile || isTablet ? 'carousel' : 'grid'}
          bgColor="white"
        />
        <div className="zodiac-animals__container--background-red">
          <MediaWithText {...horoscope} handleClick={handleClick} />
        </div>
        <SectionServices title={i18n.t('services.defaultTitle')} />
      </div>
    </AppTemplate>
  );
};

export default ZodiacAnimals;
