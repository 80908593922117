import { useEffect, useState } from 'react';

import {
  type InternalArticleSkeleton,
  getRecentArticles,
} from '@/services/articleStore';

const useRecentArticles = (options?: object) => {
  const [articles, setArticles] = useState<InternalArticleSkeleton[]>([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getArticles = () => {
    setIsLoading(true);
    getRecentArticles(options)
      .then((data) => {
        setArticles(data.items as unknown as InternalArticleSkeleton[]);
      })
      .catch((err) => {
        console.error(err.message);
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getArticles();
  }, []);

  return { articles, isLoading, error, getArticles };
};

export default useRecentArticles;
