import { useState } from 'react';

import Button from '@/components/atoms/Button';
import { Input, Select } from '@/components/atoms/Input';
import { TitleTypo } from '@/components/atoms/TitleTypo';
import FormSuccessMessage from '@/components/molecules/FormSuccessMessage';
import { contactApi } from '@/services/instagram/api';
import { i18n } from '@/translate/i18n';
import { useFormik } from 'formik';
import * as yup from 'yup';

import './styles.scss';

const schema = yup.object().shape({
  name: yup.string().required('required'),
  email: yup.string().email('invalid').required('required'),
  service: yup.string().required('required'),
  message: yup.string(),
});

interface ServiceFormProps {
  pageInfo: {
    formTitle: string;
    defaultService: string;
  };
}

interface ServiceFormFields {
  name: string;
  message?: string;
  email: string;
  service: string;
}

const serviceOptions = Object.entries(
  i18n.t('servicePages.form.options', {
    returnObjects: true,
  }),
);

const ServiceForm = ({ pageInfo }: ServiceFormProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  const CONFIRMATION_MESSAGE_DURATION = 3000;

  const handleSubmit = async (formValues: ServiceFormFields) => {
    try {
      setIsFetching(true);
      await contactApi
        .post(
          '',
          JSON.stringify({
            subject: `Contato Site - ${formValues.name}`,
            ...formValues,
          }),
        )
        .then(() => {
          formik.resetForm();
          setMessageSent(true);
          setTimeout(() => {
            setMessageSent(false);
          }, CONFIRMATION_MESSAGE_DURATION);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const formik = useFormik<ServiceFormFields>({
    initialValues: {
      name: '',
      email: '',
      service: '',
      message: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    void formik.setFieldValue(name, value);
  };

  return (
    <section className="service-form" id="#form">
      <TitleTypo>{pageInfo.formTitle}</TitleTypo>
      <form
        className="service-form__form"
        onChange={() => {
          formik.setErrors({});
        }}
        onSubmit={formik.handleSubmit}
      >
        <div className="input-box">
          <Input
            placeholder={i18n.t('servicePages.form.namePlaceholder')}
            name="name"
            onChange={handleInputChange}
            value={formik.values.name}
            type="text"
          />
          {formik.errors.name && (
            <span className="error-field">
              {i18n.t(`error.form.name.${formik.errors.name}`)}
            </span>
          )}
        </div>
        <div className="input-box">
          <Input
            name="email"
            placeholder={i18n.t('servicePages.form.emailPlaceholder')}
            onChange={handleInputChange}
            value={formik.values.email}
            type="email"
          />
          {formik.errors.email && (
            <span className="error-field">
              {i18n.t(`error.form.email.${formik.errors.email}`)}
            </span>
          )}
        </div>
        <div className="input-box">
          <Select
            defaultOption={pageInfo.defaultService}
            options={serviceOptions}
            fieldValue="service"
            onChange={(name, value) => {
              formik.setFieldValue(name, value).catch((error) => {
                console.error(error);
              });
            }}
          />
        </div>
        <div className="input-box">
          <Input
            name="message"
            placeholder={i18n.t('servicePages.form.messagePlaceholder')}
            onChange={handleInputChange}
            value={formik.values.message ?? ''}
            type="text"
          />
        </div>
        <Button disabled={isFetching} type="submit">
          {i18n.t('servicePages.form.submitButton')}
        </Button>
      </form>
      <FormSuccessMessage messageSent={messageSent} />
    </section>
  );
};

export default ServiceForm;
