import { type ChineseAnimalsPageData } from '@/components/pages/ChineseAnimals/types';
import { MEDIA_WITH_TEXT_PROPS_VALUES } from '@/settings/constants';
import { i18n } from '@/translate/i18n';

export const pageContent: ChineseAnimalsPageData = i18n.t('chineseAnimals', {
  returnObjects: true,
});

export const animals = {
  titleText: pageContent.animals.title,
  textContent: pageContent.animals.description,
  mediaPath: pageContent.animals.mediaPath,
  mediaType: MEDIA_WITH_TEXT_PROPS_VALUES.type.image,
  mediaPosition: MEDIA_WITH_TEXT_PROPS_VALUES.position.left,
  mediaOrientation: MEDIA_WITH_TEXT_PROPS_VALUES.orientation.portrait,
};
