import { type Dispatch, type SetStateAction } from 'react';

import certificationA from '@/assets/images/certifications/certification_1.png';
import Overlay from '@/components/atoms/Overlay';

import './styles.scss';

interface CertificationsProps {
  isOpen: boolean;
  handleOpen: Dispatch<SetStateAction<boolean>>;
}

function Certifications({ isOpen, handleOpen }: CertificationsProps) {
  return isOpen ? (
    <Overlay handleOpen={handleOpen}>
      <div className="certification-box">
        <img src={certificationA} alt="certification" />
      </div>
    </Overlay>
  ) : null;
}

export default Certifications;
