import ArticleCard from '@/components/molecules/ArticleCard';
import useRecentArticles from '@/hooks/useRecentArticles';
import { i18n } from '@/translate/i18n';

import './styles.scss';

const RecentArticlesSection = () => {
  const { articles, isLoading } = useRecentArticles();

  return articles.length && !isLoading ? (
    <article className="recent-articles">
      <div className="recent-articles__content-wrapper">
        <h2 className="recent-articles__title">
          {i18n.t('homePage.recentArticles.title')}
        </h2>

        <div className="recent-articles__content">
          {articles.map((article) => {
            return <ArticleCard key={article.sys.id} article={article} />;
          })}
        </div>
      </div>
    </article>
  ) : (
    <p>{i18n.t('loading')}</p>
  );
};

export default RecentArticlesSection;
