import parse from 'html-react-parser';

import { TitleTypo } from '@/components/atoms/TitleTypo';
import Typography from '@/components/atoms/Typography';
import SingleBadge from '@/components/molecules/SingleBadge';
import { type BadgeListProps } from '@/components/organisms/BadgeList/types';

import './styles.scss';

const BadgeList = ({ title, subtitle, badges }: BadgeListProps) => {
  return (
    <section className="badge-list__container">
      <TitleTypo>{title}</TitleTypo>
      {subtitle && <Typography>{parse(subtitle)}</Typography>}
      <ul className="badge-list">
        {Array.isArray(badges) &&
          badges.map(([text, imgPath]) => {
            return (
              <li className="badge-list__item" key={text}>
                <SingleBadge text={text} imgPath={imgPath} />
              </li>
            );
          })}
      </ul>
    </section>
  );
};

export default BadgeList;
