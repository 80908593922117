import parse from 'html-react-parser';
import { useState } from 'react';

import Button from '@/components/atoms/Button';
import Typography from '@/components/atoms/Typography';
import ServiceTitle from '@/components/molecules/ServiceTitle';
import { type MediaWithTextProps } from '@/components/organisms/MediaWithText/types';

import './styles.scss';

const MediaWithText = ({
  titleText,
  iconPath,
  textContent,
  mediaPath,
  mediaType,
  mediaPosition = 'right',
  mediaOrientation = 'landscape',
  ctaButton,
  ctaButtonColor = 'primary',
  ctaButtonPosition = 'bottom',
  appendix,
  expansible,
  handleExpand,
  handleClick,
}: MediaWithTextProps) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleVideoState = () => {
    const iFrameVideo = document.querySelector(
      '.media-with-text__video',
    ) as HTMLIFrameElement;

    if (isVideoPlaying) {
      iFrameVideo.contentWindow?.postMessage(
        '{"event":"command","func":"pauseVideo","args":""}',
        '*',
      );
    } else {
      iFrameVideo.contentWindow?.postMessage(
        '{"event":"command","func":"playVideo","args":""}',
        '*',
      );
    }
    setIsVideoPlaying(!isVideoPlaying);
  };

  const mediaIsPortraitImage =
    mediaPath && mediaType === 'image' && mediaOrientation === 'portrait';

  const mediaIsNotPortraitImage =
    mediaPath && mediaType === 'image' && mediaOrientation !== 'portrait';

  return (
    <section className={`media-with-text media--${mediaPosition}`}>
      {mediaIsPortraitImage && (
        <img
          className={`media-with-text__image--${mediaOrientation}`}
          src={mediaPath}
          alt={titleText ?? `${mediaPath.split('/')[-1]}`}
        />
      )}
      {titleText && <ServiceTitle title={titleText} iconPath={iconPath} />}
      <Typography styleClass="media-with-text__text">
        {parse(textContent)}
      </Typography>
      {ctaButton && ctaButtonPosition === 'middle' && (
        <Button color={ctaButtonColor} onClick={handleClick}>
          {ctaButton}
        </Button>
      )}
      {mediaIsNotPortraitImage && (
        <img
          className={`media-with-text__image--${mediaOrientation} ${
            expansible ? 'expansible' : ''
          }`}
          src={mediaPath}
          alt={titleText ?? `${mediaPath.split('/').slice(-1)[0]}`}
          onClick={expansible ? handleExpand : undefined}
        />
      )}
      {mediaPath && mediaType === 'video' && (
        <div className="media-with-text__video-container">
          <div
            onClick={handleVideoState}
            className={`media-with-text__video-thumbnail ${
              isVideoPlaying ? 'hidden' : ''
            }`}
          />
          <iframe
            className="media-with-text__video"
            src={`${mediaPath}?enablejsapi=1`}
            title={titleText ?? 'section-video'}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        </div>
      )}
      {ctaButton && ctaButtonPosition === 'bottom' && (
        <Button color={ctaButtonColor} onClick={handleClick}>
          {ctaButton}
        </Button>
      )}
      {appendix && (
        <Typography styleClass="media-with-text__appendix">
          {parse(appendix)}
        </Typography>
      )}
    </section>
  );
};

export default MediaWithText;
