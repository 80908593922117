import './styles.scss';

interface TitleTypoStyle {
  fontSize?: 'rg' | 'lg';
  color?: 'black-900' | 'white-900' | 'red-200';
  children: React.ReactNode;
}

export const HeroTitleTypo = ({
  fontSize = 'lg',
  color = 'red-200',
  children,
}: TitleTypoStyle) => (
  <h1 className={`title fs--${fontSize} ${color}`}>{children}</h1>
);

export const TitleTypo = ({
  fontSize = 'rg',
  color = 'red-200',
  children,
}: TitleTypoStyle) => (
  <h2 className={`title fs--${fontSize} ${color}`}>{children}</h2>
);

export const SubtitleTypo = ({
  fontSize = 'rg',
  color = 'red-200',
  children,
}: TitleTypoStyle) => (
  <h3 className={`subtitle fs--${fontSize} ${color}`}>{children}</h3>
);
