import { useKeenSlider } from 'keen-slider/react';
import { useEffect, useState } from 'react';

import {
  type CarrouselProps,
  type SlidesPerView,
} from '@/components/organisms/Carrousel/types';
import useScreenWidth from '@/hooks/useScreenWidth';
import { DELAY } from '@/settings/constants';
import 'keen-slider/keen-slider.scss';
import { v4 as uuidv4 } from 'uuid';

import './styles.scss';

const Carrousel = ({
  slidesPerView,
  spacing,
  count,
  indicatorType = 'dots',
  indicatorColor = 'light',
  slidesIds,
  children,
  adaptiveHeight,
  additionalAction,
  inicialSlide,
}: CarrouselProps) => {
  const { isMobile, isTablet } = useScreenWidth();
  const [currentTab, setCurrentTab] = useState(0);
  const updateHeight = (slider: any, firstRender: boolean = false) => {
    if (firstRender) {
      setTimeout(() => {
        const adjustedHeight: number =
          slider.slides[slider.track.details.rel].offsetHeight;
        slider.container.style.height = `${adjustedHeight}px`;
      }, DELAY.short);
    }

    const adjustedHeight: number =
      slider.slides[slider.track.details.rel].offsetHeight;
    slider.container.style.height = `${adjustedHeight}px`;
  };
  const [sliderRef, instanceRef] = useKeenSlider({
    slideChanged: (slider) => {
      if (isMobile && adaptiveHeight) updateHeight(slider);
      setCurrentTab(slider.track.details.rel);
    },
    created: (slider) => {
      if (isMobile && adaptiveHeight) updateHeight(slider, true);
      if (inicialSlide) {
        slider.moveToIdx(inicialSlide);
      }
    },
    mode: 'snap',
    slides: {
      perView: slidesPerView?.mobile,
      spacing,
    },
    breakpoints: {
      '(min-width: 767px)': {
        slides: {
          perView: slidesPerView?.tablet,
          spacing,
        },
      },
      '(min-width: 1025px)': {
        slides: {
          perView: slidesPerView?.desktop,
          spacing,
        },
      },
    },
    loop: true,
  });
  useEffect(() => {
    if (!instanceRef.current) return;
    instanceRef.current.options.dragEnded = (slider) => {
      const targetIndex = slider.animator.targetIdx;
      const currentSlideIndex = slider.track.details.abs;

      if (
        additionalAction?.length &&
        targetIndex &&
        currentSlideIndex !== targetIndex
      ) {
        const direction = currentSlideIndex < targetIndex ? 'right' : 'left';
        additionalAction[direction === 'right' ? 1 : 0]();
      }
    };
  }, [instanceRef, additionalAction]);
  const calculateCarouselSlides = (
    componentListLength: number,
    componentsPerView: SlidesPerView,
  ): number => {
    const singleSlideIndicators = 1;
    const multipleSlidesIndicators =
      isTablet || componentsPerView?.desktop < 3
        ? componentListLength - 1
        : componentListLength - 2;

    const indicatorQuantity = isMobile
      ? componentListLength
      : Math.max(singleSlideIndicators, multipleSlidesIndicators);

    if (isMobile) {
      return componentListLength;
    }

    return indicatorQuantity;
  };

  return (
    <section className="carrousel-box">
      <section ref={sliderRef} className="carrousel--body keen-slider">
        {children}
      </section>

      <div className={`indicators ${indicatorType}`}>
        {(indicatorType === 'dots' || indicatorType === 'all') &&
          Array.from({
            length: calculateCarouselSlides(count, slidesPerView),
          }).map((_, index) => {
            return (
              <div
                className={`indicators-${indicatorColor}--${
                  currentTab === index ? 'active' : 'inactive'
                }`}
                key={slidesIds ? slidesIds[index] : uuidv4()}
                onClick={() => instanceRef.current?.moveToIdx(index)}
              />
            );
          })}
      </div>
      {(indicatorType === 'arrows' || indicatorType === 'all') && (
        <>
          <div
            onClick={() => {
              if (additionalAction) {
                additionalAction[0]();
              }
              instanceRef.current?.prev();
            }}
            className={`indicators__arrow--${indicatorColor}--left`}
          />
          <div
            onClick={() => {
              if (additionalAction) {
                additionalAction[1]();
              }
              instanceRef.current?.next();
            }}
            className={`indicators__arrow--${indicatorColor}--right`}
          />
        </>
      )}
    </section>
  );
};

export default Carrousel;
