import { MEDIA_QUERIES } from '@/settings/constants';
import { i18n } from '@/translate/i18n';

const chineseAnimalsCards = i18n.t('chineseAnimalsCards', {
  returnObjects: true,
});

const isMobile = window.innerWidth < MEDIA_QUERIES.tablet;
const isDesktop = window.innerWidth >= MEDIA_QUERIES.desktop;

export const cardsData = Object.values(chineseAnimalsCards);

const smallScreen = {
  spacing: 20,
  slides: 1,
};

const mediumScreen = {
  spacing: 56,
  slides: 1,
};

const largeScreen = {
  spacing: 85,
  slides: 1,
};

const setSpacing = () => {
  if (isMobile) return smallScreen.spacing;
  if (isDesktop) return largeScreen.spacing;
  return mediumScreen.spacing;
};

const spaceBetweenCards = setSpacing();

export const carouselProps = {
  slidesPerView: {
    mobile: smallScreen.slides,
    tablet: mediumScreen.slides,
    desktop: largeScreen.slides,
  },
  spacing: spaceBetweenCards,
  count: cardsData.length,

  indicatorType: 'dots' as const,
  indicatorColor: 'light' as const,
};
