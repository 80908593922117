import { SubtitleTypo } from '@/components/atoms/TitleTypo';

import './styles.scss';
import { type SingleBadgeProps } from './types';

const SingleBadge = ({ text, imgPath }: SingleBadgeProps) => {
  return (
    <div className="badge">
      <img src={imgPath} alt="Badge" className="badge__image" />
      <SubtitleTypo color="black-900">{text}</SubtitleTypo>
    </div>
  );
};

export default SingleBadge;
