import parse from 'html-react-parser';

import { TitleTypo } from '@/components/atoms/TitleTypo';
import Typography from '@/components/atoms/Typography';
import { i18n } from '@/translate/i18n';

import './styles.scss';

const SectionMission = () => {
  return (
    <section className="about__mission">
      <article className="about__mission--container">
        <TitleTypo color="white-900">
          {i18n.t('aboutPage.mission.title')}
        </TitleTypo>
        <Typography color="white-900">
          {parse(i18n.t('aboutPage.mission.description'))}
        </Typography>
      </article>
    </section>
  );
};

export default SectionMission;
