import PageTitle from '@/components/atoms/PageTitle';
import { HeroTitleTypo } from '@/components/atoms/TitleTypo';
import Breadcrumb from '@/components/molecules/Breadcrumb';
import ServiceForm from '@/components/molecules/ServiceForm';
import MediaWithText from '@/components/organisms/MediaWithText';
import SectionServices from '@/components/organisms/SectionServices';
import { servicesList } from '@/components/pages/Home/data';
import {
  formPageInfo,
  howItHelpsData,
  understandingIChingData,
} from '@/components/pages/IChing/data';
import { type PageProps } from '@/components/pages/types';
import AppTemplate from '@/components/templates/AppTemplate';
import useDocTitle from '@/hooks/useDocTitle';
import { i18n } from '@/translate/i18n';
import { scrollToAnchor } from '@/utils/helpers';

import './styles.scss';

const IChing = ({ title }: PageProps) => {
  const pageId = servicesList.find((service) =>
    service.title.toLowerCase().startsWith('i ching'),
  )?.id;

  useDocTitle(title);

  return (
    <AppTemplate>
      <PageTitle>
        <HeroTitleTypo color="white-900">
          {i18n.t('servicePages.iChing.title')}
        </HeroTitleTypo>
      </PageTitle>
      <div className="understanding-i-ching__container">
        <Breadcrumb />
        <MediaWithText {...understandingIChingData} />
      </div>
      <div className="how-it-helps__container">
        <MediaWithText
          {...howItHelpsData}
          handleClick={() => {
            scrollToAnchor('#form');
          }}
        />
      </div>
      <ServiceForm pageInfo={formPageInfo} />
      <SectionServices
        title={i18n.t('services.alternativeTitle')}
        removeId={pageId}
      />
    </AppTemplate>
  );
};

export default IChing;
