import { useState } from 'react';
import { Link } from 'react-router-dom';

import LanguageSelector from '@/components/atoms/LanguageSelector';
import Navigation from '@/components/molecules/Navigation';
import { navLinks } from '@/components/organisms/Header/data';
import useScreenWidth from '@/hooks/useScreenWidth';
import { scrollToTop } from '@/utils/helpers';

import './styles.scss';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isMobile } = useScreenWidth();

  const handleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      {isMobile && (
        <div className={`${isMenuOpen ? 'menu--open' : 'menu--closed'}`} />
      )}
      <img
        src="/icons/hamburger-menu.svg"
        alt="menu"
        className="content__menu"
        onClick={handleMenuOpen}
      />
      <div className="content">
        <Link to="/">
          <img
            src="/images/logo-header.svg"
            alt="bazi-by-marina"
            className="content__logo"
            onClick={() => {
              scrollToTop();
            }}
          />
        </Link>
        <Navigation
          navLinks={navLinks}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={() => {
            handleMenuOpen();
          }}
        />
      </div>
      <LanguageSelector />
    </header>
  );
};

export default Header;
