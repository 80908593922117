import { type FooterLinks } from '@/components/organisms/Footer/types';
import { type NavLinks } from '@/components/organisms/Header/types';
import { type Service } from '@/components/pages/Home/types';
import { LANGUAGES } from '@/settings/constants';
import { i18n } from '@/translate/i18n';
import { daysToMiliseconds } from '@/utils/formatters';

export const debounce = (callback: () => void, delay: number) => {
  let timeout: ReturnType<typeof setTimeout>;

  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback.apply(null, args);
    }, delay);
  };
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const getBtnLink = ({
  links,
  parent,
  prefix,
}: {
  links: NavLinks[] | FooterLinks[];
  parent?: string | null;
  prefix: string;
}) => {
  const notFound = '/404';
  const filteredLinks = parent
    ? links.filter((link) => link?.name === parent)[0].listItems
    : links;
  const hasNoLinks = !filteredLinks?.length;

  if (hasNoLinks) {
    return notFound;
  }
  return (
    filteredLinks.find((link) => link?.name.startsWith(prefix))?.path ||
    notFound
  );
};

export const scrollToAnchor = (anchor: string) => {
  const element = document.getElementById(anchor);

  element?.scrollIntoView({
    behavior: 'smooth',
  });
};

export const getPageId = (pageList: Service[], prefix: string) => {
  return pageList.find((page) => page.title.toLowerCase().startsWith(prefix))
    ?.id;
};

export const setCookies = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + daysToMiliseconds(days));

  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

export const getCookies = (name: string) => {
  const cookieName = `${name}=`;
  const cookies = document.cookie.split(';');

  const retrievedCookie = cookies.find((cookie) =>
    cookie.trim().startsWith(cookieName),
  );

  return retrievedCookie?.substring(cookieName.length, retrievedCookie.length);
};

export const isValidLanguage = (language: string) => {
  return LANGUAGES.some((lang) => lang.value === language);
};

export const checkES6Support = () => {
  const isSupported = typeof [].map === 'function';

  if (!isSupported) {
    alert(i18n.t('ecmaNotSupported'));
  }
};
