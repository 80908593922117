import Typography from '@/components/atoms/Typography';

import { type IconPath } from '../ServiceTitle/types';
import './styles.scss';

interface BulletTextProps {
  bullet: [IconPath, string];
}

const BulletText = ({ bullet }: BulletTextProps) => {
  const [iconPath, text] = bullet;

  return (
    <li className="bullet-text__item">
      <img src={iconPath} alt="bullet-text__icon" />
      <Typography>{text}</Typography>
    </li>
  );
};

export default BulletText;
