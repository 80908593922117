import { Link } from 'react-router-dom';

interface ArticleImageLinkProps {
  to: string;
  external?: boolean;
  children?: React.ReactNode;
}

const ArticleImageLink = ({
  to,
  external,
  children,
}: ArticleImageLinkProps) => {
  return (
    <Link
      to={to}
      className={`article-card__${external ? 'external' : 'internal'}`}
      target={external ? '_blank' : '_self'}
      rel="noreferrer"
    >
      {children || ''}
    </Link>
  );
};

export default ArticleImageLink;
