import parse from 'html-react-parser';
import { useState } from 'react';

import Typography from '@/components/atoms/Typography';

import './styles.scss';

interface AccordionProps {
  title: string;
  children: React.ReactNode;
}

const Accordion = ({ title, children }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen((prevstate) => !prevstate);
  };

  return (
    <div className={`accordion ${isOpen ? 'active' : ''}`}>
      <div className="accordion__title" onClick={handleOpen}>
        <span className="accordion__title-icon" />
        <Typography>{parse(title)}</Typography>
      </div>
      <div className="accordion__content">{children}</div>
    </div>
  );
};

export default Accordion;
