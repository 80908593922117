import InfoCard from '@/components/molecules/InfoCard';
import Carrousel from '@/components/organisms/Carrousel';
import { carouselProps } from '@/components/organisms/InfoCardsSection/data';
import useInfoCards from '@/hooks/useInfoCards';
import useScreenWidth from '@/hooks/useScreenWidth';

import './styles.scss';

interface InfoCardsSectionProps {
  type: 'carousel' | 'grid';
  collection: string;
}

const InfoCardsSection = ({ type, collection }: InfoCardsSectionProps) => {
  const { infoCards, isLoading, error } = useInfoCards(collection);

  const { isResizing } = useScreenWidth();

  if (isResizing) return null;

  return !isLoading && !error ? (
    <div className={`info-card-carousel__container ${type}`}>
      {type === 'grid' &&
        infoCards.map((cardContent) => {
          return (
            <InfoCard
              key={cardContent?.sys?.id}
              isCarousel={false}
              data={cardContent}
            />
          );
        })}
      {type === 'carousel' && (
        <Carrousel
          {...carouselProps}
          count={infoCards.length}
          indicatorColor="red"
          adaptiveHeight={true}
        >
          {infoCards.map((cardContent) => {
            return (
              <InfoCard
                key={cardContent?.sys?.id}
                data={cardContent}
                isCarousel
              />
            );
          })}
        </Carrousel>
      )}
    </div>
  ) : (
    <></>
  );
};

export default InfoCardsSection;
