import { useState } from 'react';

import PageTitle from '@/components/atoms/PageTitle';
import { HeroTitleTypo } from '@/components/atoms/TitleTypo';
import Breadcrumb from '@/components/molecules/Breadcrumb';
import Certifications from '@/components/molecules/Certifications';
import ServiceForm from '@/components/molecules/ServiceForm';
import BadgeList from '@/components/organisms/BadgeList';
import KiSignatureTabs from '@/components/organisms/KiSignatureTabs';
import MediaWithText from '@/components/organisms/MediaWithText';
import SectionServices from '@/components/organisms/SectionServices';
import { servicesList } from '@/components/pages/Home/data';
import {
  formPageInfo,
  pageContent,
  signatureTabs,
  thePowerOfNineStar,
  whyNineStar,
} from '@/components/pages/NineStar/data';
import { type PageProps } from '@/components/pages/types';
import AppTemplate from '@/components/templates/AppTemplate';
import useDocTitle from '@/hooks/useDocTitle';
import { i18n } from '@/translate/i18n';
import { getPageId, scrollToAnchor } from '@/utils/helpers';

import './styles.scss';

const NineStarKi = ({ title }: PageProps) => {
  const [isCertificationModalOpen, setIsCertificationModalOpen] =
    useState(false);

  useDocTitle(title);

  return (
    <AppTemplate>
      <PageTitle>
        <HeroTitleTypo color="white-900">
          {i18n.t('servicePages.nineStar.title')}
        </HeroTitleTypo>
      </PageTitle>
      <div className="the-power-of-nine-star__container">
        <Breadcrumb />
        <MediaWithText {...thePowerOfNineStar} />
      </div>
      <KiSignatureTabs tabs={signatureTabs} />
      <div className="why-nine-star__container">
        <MediaWithText {...whyNineStar.partOne} />
        <MediaWithText
          {...whyNineStar.partTwo}
          expansible
          handleExpand={() => {
            setIsCertificationModalOpen(!isCertificationModalOpen);
          }}
          handleClick={() => {
            scrollToAnchor('#form');
          }}
        />
        <Certifications
          isOpen={isCertificationModalOpen}
          handleOpen={() => {
            setIsCertificationModalOpen(!isCertificationModalOpen);
          }}
        />
      </div>
      <BadgeList
        title={i18n.t('servicePages.nineStar.badgeList.title')}
        badges={pageContent.badgeList.badges}
      />
      <ServiceForm pageInfo={formPageInfo} />
      <SectionServices
        title={i18n.t('services.alternativeTitle')}
        removeId={
          getPageId(servicesList, 'ki') || getPageId(servicesList, 'nine')
        }
      />
    </AppTemplate>
  );
};

export default NineStarKi;
