import {
  type NavLink,
  type NavLinks,
} from '@/components/organisms/Header/types';
import { createLink } from '@/utils/formatters';

export const navLinks: NavLinks[] = [
  createLink('header.home') as NavLink,
  createLink('header.about') as NavLink,
  {
    ...(createLink('header.services.label') as NavLink),
    listItems: [
      createLink('header.services.bazi', true, 'header.services.label'),
      createLink('header.services.iChing', true, 'header.services.label'),
      createLink('header.services.fengShui', true, 'header.services.label'),
      createLink('header.services.nineStar', true, 'header.services.label'),
      createLink(
        'header.services.dateSelection',
        true,
        'header.services.label',
      ),
    ],
  },
  createLink('header.press') as NavLink,
  createLink('header.faq') as NavLink,
  createLink('header.contact') as NavLink,
  createLink('header.articles') as NavLink,
];
