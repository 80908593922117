import parse from 'html-react-parser';

import Typography from '@/components/atoms/Typography';
import { i18n } from '@/translate/i18n';

import './styles.scss';

const AboutSectionMarina = () => {
  return (
    <section className="about__marina">
      <img
        src="/images/about/marina.webp"
        alt="marina-caramez"
        className="about__image"
      />
      <Typography>{parse(i18n.t('aboutPage.marina.description'))}</Typography>
    </section>
  );
};

export default AboutSectionMarina;
