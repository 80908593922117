import { TitleTypo } from '@/components/atoms/TitleTypo';
import ServiceCard from '@/components/molecules/ServiceCard';
import { carouselProps } from '@/components/organisms/SectionServices/data';
import { servicesList } from '@/components/pages/Home/data';
import useScreenWidth from '@/hooks/useScreenWidth';

import Carrousel from '../Carrousel';
import './styles.scss';

interface SectionServicesProps {
  title: string;
  removeId?: string;
}

const SectionServices = ({ title, removeId }: SectionServicesProps) => {
  const { isDesktop } = useScreenWidth();

  const filteredList = removeId
    ? servicesList.filter((service) => service.id !== removeId)
    : servicesList;

  return (
    <section className="home__services">
      <TitleTypo>{title}</TitleTypo>

      <Carrousel
        {...carouselProps}
        count={filteredList.length}
        indicatorType={!isDesktop ? ('dots' as const) : ('all' as const)}
      >
        {Array.isArray(filteredList) &&
          filteredList.map((service) => (
            <ServiceCard key={service.id} {...service} />
          ))}
      </Carrousel>
    </section>
  );
};

export default SectionServices;
