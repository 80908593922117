import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import useRecentArticles from '@/hooks/useRecentArticles';
import { i18n } from '@/translate/i18n';
import { toRouteParam } from '@/utils/formatters';

import './styles.scss';

const NUMBER_OF_ARTICLES = 5;

const RecentArticlesColumn = () => {
  const { slug } = useParams();
  const { articles, getArticles } = useRecentArticles({
    'fields.slug[ne]': slug,
    limit: NUMBER_OF_ARTICLES,
  });

  useEffect(() => {
    getArticles();
  }, [slug]);

  return (
    <article className="recent-articles-column">
      <h2 className="recent-articles-column__title">
        {i18n.t('articles.recentArticlesColumn.title')}
      </h2>

      {articles.map((article) => {
        const articleLink = `${toRouteParam(i18n.t('articles.title'), true)}/${
          article.fields.slug
        }`;

        return (
          <div key={article.sys.id} className="recent-articles-column__wrapper">
            <Link
              to={articleLink}
              className="recent-articles-column__article-title"
            >
              {article?.fields?.title}
            </Link>
            <Link to={articleLink} className="recent-articles-column__link">
              {i18n.t('articles.recentArticlesColumn.link')}
            </Link>
          </div>
        );
      })}
    </article>
  );
};

export default RecentArticlesColumn;
