import './styles.scss';

interface ButtonStyle extends React.ComponentPropsWithoutRef<'button'> {
  size?: 'normal' | 'large' | 'auto';
  color?: 'primary' | 'secondary';
  anchor?: string;
  link?: string;
  children: React.ReactNode;
}

const Button = ({
  size = 'normal',
  color = 'primary',
  anchor,
  link,
  children,
  ...rest
}: ButtonStyle) => {
  return (
    <button
      className={`btn-size--${size} btn-color--${color}`}
      type="button"
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
