import {
  MEDIA_QUERIES,
  MEDIA_WITH_TEXT_PROPS_VALUES,
} from '@/settings/constants';
import { i18n } from '@/translate/i18n';

import { type DateSelectionPageData } from './types';

const isMobile = window.innerWidth < MEDIA_QUERIES.tablet;

export const pageContent: DateSelectionPageData = i18n.t(
  'servicePages.dateSelection',
  {
    returnObjects: true,
  },
);

export const auspiciousSelection = {
  titleText: pageContent.auspiciousSelection.title,
  iconPath: pageContent.auspiciousSelection.kanjiPath,
  textContent: pageContent.auspiciousSelection.description,
  mediaPath: pageContent.auspiciousSelection.mediaPath,
  mediaType: MEDIA_WITH_TEXT_PROPS_VALUES.type.image,
  mediaOrientation: isMobile
    ? MEDIA_WITH_TEXT_PROPS_VALUES.orientation.landscape
    : MEDIA_WITH_TEXT_PROPS_VALUES.orientation.portrait,
};

export const aPowerfulTool = {
  titleText: pageContent.aPowerfulTool.title,
  textContent: pageContent.aPowerfulTool.description,
  mediaPath: pageContent.aPowerfulTool.mediaPath,
  mediaType: MEDIA_WITH_TEXT_PROPS_VALUES.type.image,
  mediaOrientation: isMobile
    ? MEDIA_WITH_TEXT_PROPS_VALUES.orientation.landscape
    : MEDIA_WITH_TEXT_PROPS_VALUES.orientation.portrait,
  mediaPosition: MEDIA_WITH_TEXT_PROPS_VALUES.position.left,
};

export const formPageInfo = {
  formTitle: pageContent.formTitle,
  defaultService: i18n.t('servicePages.form.options.dateSelection'),
};
