import PageTitle from '@/components/atoms/PageTitle';
import { HeroTitleTypo } from '@/components/atoms/TitleTypo';
import Breadcrumb from '@/components/molecules/Breadcrumb';
import ServiceForm from '@/components/molecules/ServiceForm';
import BadgeList from '@/components/organisms/BadgeList';
import MediaWithText from '@/components/organisms/MediaWithText';
import SectionServices from '@/components/organisms/SectionServices';
import {
  aPowerfulTool,
  auspiciousSelection,
  formPageInfo,
  pageContent,
} from '@/components/pages/DateSelection/data';
import { servicesList } from '@/components/pages/Home/data';
import { type PageProps } from '@/components/pages/types';
import AppTemplate from '@/components/templates/AppTemplate';
import useDocTitle from '@/hooks/useDocTitle';
import { i18n } from '@/translate/i18n';
import { getPageId } from '@/utils/helpers';

import './styles.scss';

const DateSelection = ({ title }: PageProps) => {
  useDocTitle(title);

  return (
    <AppTemplate>
      <PageTitle>
        <HeroTitleTypo color="white-900">
          {i18n.t('servicePages.dateSelection.title')}
        </HeroTitleTypo>
      </PageTitle>
      <div className="auspicious-selection__container">
        <Breadcrumb />
        <MediaWithText {...auspiciousSelection} />
      </div>
      <div className="a-powerful-tool__container">
        <MediaWithText {...aPowerfulTool} />
      </div>
      <BadgeList
        title={i18n.t('servicePages.dateSelection.badgeList.title')}
        subtitle={i18n.t('servicePages.dateSelection.badgeList.subtitle')!}
        badges={pageContent.badgeList.badges}
      />
      <ServiceForm pageInfo={formPageInfo} />
      <SectionServices
        title={i18n.t('services.alternativeTitle')}
        removeId={
          getPageId(servicesList, 'seleção') || getPageId(servicesList, 'date')
        }
      />
    </AppTemplate>
  );
};

export default DateSelection;
