import { TitleTypo } from '@/components/atoms/TitleTypo';
import useInstagramPosts from '@/hooks/useInstagramPosts';
import { i18n } from '@/translate/i18n';

import './styles.scss';

const SectionInstagram = () => {
  const { posts, loading, error } = useInstagramPosts();

  return (
    <section className="home__instagram">
      <TitleTypo>{i18n.t('homePage.instagram.title')}</TitleTypo>
      <div className="home__instagram-images">
        {!loading &&
          !error &&
          posts?.map((post) => (
            <a
              key={post.id}
              href={post.permalink}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="home__instagram-post"
                src={post.media_url}
                alt="instagram-post"
              />
            </a>
          ))}
      </div>
    </section>
  );
};

export default SectionInstagram;
