import { HTTP_STATUS, INSTAGRAM_STORAGE_KEY } from '@/settings/constants';
import axios from 'axios';

const INSTAGRAM_TOKEN = import.meta.env.VITE_INSTAGRAM_TOKEN;

const CONTENT_LIMIT = 20;

export const contactApi = axios.create({
  baseURL: import.meta.env.VITE_FORM_TOKEN,
  headers: {
    'Content-Type': 'application/json',
  },
});

const api = axios.create({
  baseURL: 'https://graph.instagram.com',
});

export const getRefreshToken = async (token: string) => {
  const abortController = new AbortController();

  const { data } = await api.get('/refresh_access_token?', {
    params: {
      grant_type: 'ig_refresh_token',
      access_token: token,
    },
  });

  return { token: data, abortController };
};

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const localStorageToken =
      localStorage.getItem(INSTAGRAM_STORAGE_KEY) ?? INSTAGRAM_TOKEN;

    if (error.response.status === HTTP_STATUS.unauthorized) {
      const { token: newToken } = await getRefreshToken(
        localStorageToken ?? '',
      );

      localStorage.setItem(INSTAGRAM_STORAGE_KEY, newToken.access_token);
      originalRequest.params.access_token = newToken.access_token;

      return await api(originalRequest);
    }

    return await Promise.reject(error);
  },
);

export const getPosts = async (token: string, options?: { after: string }) => {
  const abortController = new AbortController();

  const { data } = await api.get('/me/media', {
    params: {
      fields: 'id,media_type,media_url,permalink',
      access_token: token,
      limit: CONTENT_LIMIT,
      ...options,
    },
  });

  return { postList: data, abortController };
};
