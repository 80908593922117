import parse from 'html-react-parser';

import { SubtitleTypo } from '@/components/atoms/TitleTypo';
import Typography from '@/components/atoms/Typography';
import {
  carouselProps,
  timelineEvents,
} from '@/components/organisms/SectionTimeline/data';
import {
  type SectionTimelineProps,
  type TimelineEventProps,
} from '@/components/organisms/SectionTimeline/types';

import Carrousel from '../Carrousel';
import './styles.scss';

const TimelineEvent = ({ year, content }: TimelineEventProps) => {
  return (
    <article className="section-timeline__event keen-slider__slide">
      <SubtitleTypo color="white-900">{year}</SubtitleTypo>
      <div className="separator">
        <div className="separator__main-line">
          <img
            className="separator__event-background"
            src="/images/about/timeline-background.webp"
            alt="timeline-background"
          />
          <div className="separator__circle"></div>
          <hr className="separator__line"></hr>
        </div>
        <div className="separator__circle" />
        <div className="separator__circle" />
        <div className="separator__circle" />
      </div>
      <Typography>{parse(content)}</Typography>
    </article>
  );
};

const SectionTimeline = ({ isMobile, isTablet }: SectionTimelineProps) => {
  const mapEvents = () =>
    Array.isArray(timelineEvents) &&
    timelineEvents.map((event) => (
      <TimelineEvent key={event.year} {...event} />
    ));

  return (
    <section className="section-timeline">
      <img
        className="section-timeline__background"
        src="/images/about/timeline-background.webp"
        alt="timeline-background"
      />
      {!isMobile && !isTablet ? (
        <div className="section-timeline--static">{mapEvents()}</div>
      ) : (
        <Carrousel {...carouselProps}>{mapEvents()}</Carrousel>
      )}
    </section>
  );
};

export default SectionTimeline;
