import { Link, useLocation } from 'react-router-dom';

import Typography from '@/components/atoms/Typography';
import { I18N_STORAGE_KEY } from '@/settings/constants';
import { i18n } from '@/translate/i18n';
import { toRouteParam } from '@/utils/formatters';

import './styles.scss';

const SPLIT_REF_INDEX = 2;

interface BreadcrumbProps {
  title?: {
    path: string;
    label: string;
  };
  articleBreadcrumb?: {
    path: string;
    label: string;
  };
}

const Breadcrumb = ({ title, articleBreadcrumb }: BreadcrumbProps) => {
  const { pathname } = useLocation();
  const locale = localStorage.getItem(I18N_STORAGE_KEY) ?? 'pt-BR';
  const curLang = `/${locale}`;

  const breadcrumbPath = pathname.split(curLang)[1];

  let menuItems = breadcrumbPath
    ?.split('/')
    .filter((item) => item)
    .map((item, idx) => {
      const decodedString = decodeURIComponent(item).replace(/-/g, ' ');
      return {
        path: `${curLang}/${breadcrumbPath
          .split('/', idx + SPLIT_REF_INDEX)
          .filter((i) => i)
          .join('/')}`,
        label: decodedString.startsWith('bazi') ? 'BaZi' : decodedString,
      };
    });

  if (title && menuItems) {
    menuItems.pop();
    menuItems.push(title);
  }

  if (title && articleBreadcrumb) {
    menuItems = [
      {
        label: i18n.t('articles.title'),
        path: `${toRouteParam(i18n.t('articles.title'), true)}`,
      },
      articleBreadcrumb,
      title,
    ];
  }

  return (
    <nav className="breadcrumb">
      <Link to="/home">
        <Typography color="red-200" fontSize="sm" fontWeight="bold">
          {i18n.t('breadcrumbRoot')}
        </Typography>
      </Link>
      {menuItems?.map(({ label, path }) => (
        <div className="breadcrumb--content" key={label}>
          <img src="/icons/double-chevron-right.svg" />
          <Link to={path}>
            <Typography
              color="red-200"
              fontSize="sm"
              fontWeight="bold"
              styleClass="capitalize"
            >
              {label}
            </Typography>
          </Link>
        </div>
      ))}
    </nav>
  );
};

export default Breadcrumb;
