import { createBrowserRouter } from 'react-router-dom';

import App from '@/App';
import About from '@/components/pages/About';
import ArticleDetails from '@/components/pages/ArticleDetails';
import Articles from '@/components/pages/Articles';
import BaZi from '@/components/pages/BaZi';
import ChineseAnimals from '@/components/pages/ChineseAnimals';
import Contact from '@/components/pages/Contact';
import DateSelection from '@/components/pages/DateSelection';
import ErrorPage from '@/components/pages/ErrorPage';
import Faq from '@/components/pages/Faq';
import FengShui from '@/components/pages/FengShui';
import IChing from '@/components/pages/IChing';
import NineStarKi from '@/components/pages/NineStar';
import PredictionArticle from '@/components/pages/PredictionArticle';
import Press from '@/components/pages/Press';
import PrivacyPolicy from '@/components/pages/PrivacyPolicy';
import Services from '@/components/pages/Services';
import ZodiacAnimals from '@/components/pages/ZodiacAnimals';
import { i18n } from '@/translate/i18n';
import { toRouteParam } from '@/utils/formatters';

interface Route {
  path: string;
  element: JSX.Element;
  errorElement?: JSX.Element;
  children?: Route[];
}

interface PageTitles {
  main: string;
  home: string;
  about: string;
  services: {
    main: string;
    bazi: string;
    iChing: string;
    fengShui: string;
    nineStar: string;
    dateSelection: string;
  };
  press?: string;
  faq?: string;
  contact: string;
  articles: string;
  zodiacAnimals: string;
  privacyPolicy: string;
  errorPage: string;
  chineseAnimals: string;
}

const documentTitles: PageTitles = i18n.t('documentTitle', {
  returnObjects: true,
});

const home: Route = {
  path: '/',
  element: <App />,
  errorElement: (
    <ErrorPage title={`${documentTitles.main} - ${documentTitles.errorPage}`} />
  ),
  children: [
    {
      path: '/home',
      element: <App />,
    },
  ],
};

const about = {
  path: `${toRouteParam(i18n.t('header.about'), true)}`,
  element: <About title={`${documentTitles.main} - ${documentTitles.about}`} />,
};

const contact = {
  path: `${toRouteParam(i18n.t('header.contact'), true)}`,
  element: (
    <Contact title={`${documentTitles.main} - ${documentTitles.contact}`} />
  ),
};

const services = {
  path: `${toRouteParam(i18n.t('header.services.label'), true)}`,
  element: (
    <Services
      title={`${documentTitles.main} - ${documentTitles.services.main}`}
    />
  ),
};

const bazi = {
  path: `${toRouteParam(i18n.t('header.services.label'), true)}${toRouteParam(
    i18n.t('header.services.bazi'),
  )}`,
  element: (
    <BaZi title={`${documentTitles.main} - ${documentTitles.services.bazi}`} />
  ),
};

const iChing = {
  path: `${toRouteParam(i18n.t('header.services.label'), true)}${toRouteParam(
    i18n.t('header.services.iChing'),
  )}`,
  element: (
    <IChing
      title={`${documentTitles.main} - ${documentTitles.services.iChing}`}
    />
  ),
};

const fengShui = {
  path: `${toRouteParam(i18n.t('header.services.label'), true)}${toRouteParam(
    i18n.t('header.services.fengShui'),
  )}`,
  element: (
    <FengShui
      title={`${documentTitles.main} - ${documentTitles.services.fengShui}`}
    />
  ),
};

const nineStar = {
  path: `${toRouteParam(i18n.t('header.services.label'), true)}${toRouteParam(
    i18n.t('header.services.nineStar'),
  )}`,
  element: (
    <NineStarKi
      title={`${documentTitles.main} - ${documentTitles.services.nineStar}`}
    />
  ),
};

const dateSelection = {
  path: `${toRouteParam(i18n.t('header.services.label'), true)}${toRouteParam(
    i18n.t('header.services.dateSelection'),
  )}`,
  element: (
    <DateSelection
      title={`${documentTitles.main} - ${documentTitles.services.dateSelection}`}
    />
  ),
};

const faq = {
  path: `${toRouteParam(i18n.t('header.faq'), true)}`,
  element: (
    <Faq title={`${documentTitles.main} - ${documentTitles.faq ?? ''}`} />
  ),
};

const privacyPolicy = {
  path: toRouteParam(i18n.t('privacyPolicy.label'), true),
  element: (
    <PrivacyPolicy
      title={`${documentTitles.main} - ${documentTitles.privacyPolicy}`}
    />
  ),
};

const articles = {
  path: `${toRouteParam(i18n.t('articles.title'), true)}`,
  element: (
    <Articles title={`${documentTitles.main} - ${documentTitles.articles}`} />
  ),
};

const predictionArticle = {
  path: `${toRouteParam(
    i18n.t('articles.title'),
    true,
  )}/:slug/:year/:zodiacAnimal`,
  element: (
    <PredictionArticle
      title={`${documentTitles.main} - ${documentTitles.articles}`}
    />
  ),
};

const articleDetails = {
  path: `${toRouteParam(i18n.t('articles.title'), true)}/:slug`,
  element: (
    <ArticleDetails
      title={`${documentTitles.main} - ${documentTitles.articles}`}
    />
  ),
};

const zodiacAnimals = {
  path: `${toRouteParam(i18n.t('footer.institutional.zodiac'), true)}`,
  element: (
    <ZodiacAnimals
      title={`${documentTitles.main} - ${documentTitles.zodiacAnimals}`}
    />
  ),
};

const press = {
  path: `${toRouteParam(i18n.t('header.press'), true)}`,
  element: (
    <Press title={`${documentTitles.main} - ${documentTitles.press ?? ''}`} />
  ),
};

const chineseAnimals = {
  path: `${toRouteParam(
    i18n.t('footer.institutional.chineseAnimals'),
    true,
  )}/:id`,
  element: (
    <ChineseAnimals
      title={`${documentTitles.main} - ${documentTitles.chineseAnimals}`}
    />
  ),
};

const router = createBrowserRouter([
  home,
  about,
  services,
  contact,
  bazi,
  iChing,
  fengShui,
  nineStar,
  dateSelection,
  faq,
  privacyPolicy,
  articles,
  predictionArticle,
  articleDetails,
  zodiacAnimals,
  press,
  chineseAnimals,
]);

export default router;
