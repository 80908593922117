import { type LanguageOption } from '@/context/LanguageContext/context';
import { v4 as uuidv4 } from 'uuid';

export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60;
export const ONE_HOUR = 60;
export const ONE_DAY = 24 * ONE_HOUR * ONE_MINUTE * ONE_SECOND;
export const INSTAGRAM_STORAGE_KEY = 'instagram-token';
export const I18N_STORAGE_KEY = 'i18nextLng';

export const DELAY = {
  short: 200,
  medium: 500,
  long: 1000,
} as const;

export const MEDIA_QUERIES = {
  mobile: 320,
  tablet: 768,
  laptop: 1025,
  desktop: 1281,
  infinity: 1440,
} as const;

export const MEDIA_WITH_TEXT_PROPS_VALUES = {
  type: {
    image: 'image',
    video: 'video',
  },
  orientation: {
    square: 'square',
    landscape: 'landscape',
    portrait: 'portrait',
  },
  position: {
    left: 'left',
    right: 'right',
    middle: 'middle',
  },
  buttonColor: {
    primary: 'primary',
    secondary: 'secondary',
  },
  buttonPosition: {
    middle: 'middle',
    bottom: 'bottom',
  },
} as const;

export const HTTP_STATUS = {
  ok: 200,
  created: 201,
  accepted: 202,
  noContent: 204,
  badRequest: 400,
  unauthorized: 401,
  notFound: 404,
  internalServerError: 500,
} as const;

export const LANGUAGES: LanguageOption[] = [
  {
    id: uuidv4(),
    name: 'PT',
    value: 'pt-BR',
  },
  {
    id: uuidv4(),
    name: 'EN',
    value: 'en-US',
  },
];
