import { type ChangeEvent, useEffect, useState } from 'react';

import Typography from '../Typography';
import './styles.scss';

interface InputProps {
  type: string;
  value: string;
  name: string;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

interface SelectProps {
  defaultOption: string;
  fieldValue: string;
  options: string[][];
  onChange: (name: string, value: string) => void;
}

export const Select = ({
  options,
  fieldValue,
  onChange,
  defaultOption,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const defaultSelection = defaultOption;
  const [selectedOption, setSelectedOption] = useState(defaultSelection);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (event: React.MouseEvent<HTMLLIElement>) => {
    const clicked = (event.target as HTMLLIElement).getAttribute(
      'value',
    ) as string;

    const [, selected] = options.find(
      ([value]) => value === clicked,
    ) as string[];

    onChange(fieldValue, selected);
    setSelectedOption(selected);
    setIsOpen(false);
  };

  useEffect(() => {
    onChange(fieldValue, selectedOption);
  }, []);

  return (
    <div className="select-box">
      <div className="select-box__header" onClick={handleOpen}>
        <Typography>
          <strong>{selectedOption}</strong>
        </Typography>
        <img
          src="/icons/chevron-down-red.svg"
          alt="chevron-down"
          className="select-box__icon"
        />
      </div>
      {isOpen && (
        <div className="select-box__list-container">
          <ul className="select-box__list">
            {options.map(([value, name]) => (
              <li
                className="select-box__option"
                onClick={handleSelect}
                key={value}
                value={value}
              >
                {name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export const Input = ({
  type,
  onChange,
  value,
  name,
  placeholder,
}: InputProps) => {
  return (
    <input
      placeholder={placeholder}
      name={name}
      type={type}
      onChange={onChange}
      value={value}
      className="input-field"
    />
  );
};
