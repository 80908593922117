import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

import Button from '@/components/atoms/Button';
import { TitleTypo } from '@/components/atoms/TitleTypo';
import Typography from '@/components/atoms/Typography';
import { navLinks } from '@/components/organisms/Header/data';
import { i18n } from '@/translate/i18n';
import { getBtnLink } from '@/utils/helpers';

import './styles.scss';

const SectionWhoisMarina = () => {
  return (
    <section className="home__whoismarina">
      <div className="home__whoismarina-section-picture">
        <img
          src="/images/home/marina.webp"
          alt="Marina"
          className="home__whoismarina-image"
        />
      </div>
      <div className="home__whoismarina-content">
        <TitleTypo>{i18n.t('homePage.whoIsMarina.title')}</TitleTypo>
        <Typography>
          {parse(i18n.t('homePage.whoIsMarina.description'))}
        </Typography>
        <Link
          to={getBtnLink({
            links: navLinks,
            prefix: i18n.t('header.about'),
          })}
        >
          <Button>{i18n.t('homePage.whoIsMarina.ctaButton')}</Button>
        </Link>
      </div>
    </section>
  );
};

export default SectionWhoisMarina;
