import { useState } from 'react';

import Button from '@/components/atoms/Button';
import { Input } from '@/components/atoms/Input';
import Typography from '@/components/atoms/Typography';
import FormSuccessMessage from '@/components/molecules/FormSuccessMessage';
import { contactApi } from '@/services/instagram/api';
import { i18n } from '@/translate/i18n';
import { useFormik } from 'formik';
import * as yup from 'yup';

import './styles.scss';

const schema = yup.object().shape({
  email: yup.string().email('invalid').required('required'),
  name: yup.string().required('required'),
  message: yup.string().required('required'),
});

interface ContactFields {
  name: string;
  message: string;
  email: string;
}

const ContactForm = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  const confirmationMessageDuration = 3000;

  const handleSubmit = async (formValues: ContactFields) => {
    try {
      setIsFetching(true);
      await contactApi
        .post(
          '',
          JSON.stringify({
            subject: `Contato Site - ${formValues.name}`,
            ...formValues,
          }),
        )
        .then(() => {
          formik.resetForm();
          setMessageSent(true);
          setTimeout(() => {
            setMessageSent(false);
          }, confirmationMessageDuration);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const formik = useFormik<ContactFields>({
    initialValues: {
      email: '',
      name: '',
      message: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    void formik.setFieldValue(name, value);
  };

  return (
    <section className="contact-form">
      <Typography>{i18n.t('contact.formAdvice')}</Typography>
      <form
        className="form-container"
        onChange={() => {
          formik.setErrors({});
        }}
        onSubmit={formik.handleSubmit}
      >
        <div className="input-box">
          <Input
            name="name"
            placeholder={i18n.t('contact.form.name')}
            type="text"
            value={formik.values.name}
            onChange={handleInputChange}
          />
          {formik.errors.name && (
            <span className="error-field">
              {i18n.t(`error.form.name.${formik.errors.name}`)}
            </span>
          )}
        </div>
        <div className="input-box">
          <Input
            name="email"
            placeholder={i18n.t('contact.form.email')}
            type="text"
            value={formik.values.email}
            onChange={handleInputChange}
          />
          {formik.errors.email && (
            <span className="error-field">
              {i18n.t(`error.form.email.${formik.errors.email}`)}
            </span>
          )}
        </div>
        <div className="area-box">
          <textarea
            className="text-area"
            name="message"
            value={formik.values.message}
            placeholder={i18n.t('contact.form.message') ?? ''}
            onChange={handleInputChange}
          />
          {formik.errors.message && (
            <span className="error-field">
              {i18n.t(`error.form.message.${formik.errors.message}`)}
            </span>
          )}
        </div>
        <Button disabled={isFetching} type="submit">
          {i18n.t('contact.form.ctaButton')}
        </Button>
      </form>
      <FormSuccessMessage messageSent={messageSent} />
    </section>
  );
};

export default ContactForm;
