import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import { SubtitleTypo } from '@/components/atoms/TitleTypo';
import { type InfoCardsProps } from '@/components/molecules/InfoCard/types';
import remarkGfm from 'remark-gfm';

import './styles.scss';

function InfoCard({ isCarousel, data }: InfoCardsProps) {
  const {
    title,
    description,
    firstTopic,
    firstTopicContent,
    secondTopic,
    secondTopicContent,
    image,
  } = data?.fields ?? {};

  return (
    <article
      className={`${
        isCarousel ? 'carousel-view' : 'grid-view'
      } info-card keen-slider__slide`}
    >
      {image?.fields?.file?.url && (
        <img
          className="info-card__icon"
          src={image.fields.file.url}
          alt={`${title ?? 'image'}-icon`}
        />
      )}

      <div className="info-card__content">
        <SubtitleTypo>{title}</SubtitleTypo>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {description ?? ''}
        </ReactMarkdown>
        <div className="info-card__topic">
          <strong>{firstTopic}</strong>{' '}
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {firstTopicContent ?? ''}
          </ReactMarkdown>
        </div>
        <div className="info-card__topic">
          <strong>{secondTopic} </strong>{' '}
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {secondTopicContent ?? ''}
          </ReactMarkdown>
        </div>
      </div>
    </article>
  );
}

export default InfoCard;
