import Typography from '@/components/atoms/Typography';
import { i18n } from '@/translate/i18n';

import './styles.scss';

const FormSuccessMessage = ({ messageSent }: { messageSent: boolean }) => {
  return (
    <div
      className={`contact-form__success-message ${
        messageSent ? 'visible' : ''
      }`}
    >
      <Typography styleClass="contact-form__text">
        {i18n.t('contact.form.successMessage')}
      </Typography>
    </div>
  );
};

export default FormSuccessMessage;
