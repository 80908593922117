import { I18N_STORAGE_KEY, ONE_DAY } from '@/settings/constants';
import { i18n } from '@/translate/i18n';
import { v4 as uuidv4 } from 'uuid';

export const toRouteParam = (text: string, lngKey?: boolean) => {
  if (!text) return '/404-page';

  const output = text.trim().toLowerCase().replace(/ /g, '-');
  const locale = localStorage.getItem(I18N_STORAGE_KEY) ?? 'pt-BR';

  if (output === 'home') return '/';

  return `/${lngKey ? locale + '/' : ''}${output}`;
};

export const createLink = (name: string, path = true, pathNest?: string) => {
  if (!i18n.t(name)) return null;

  return {
    id: uuidv4(),
    name: i18n.t(name),
    path: path
      ? `${pathNest ? toRouteParam(i18n.t(pathNest), true) : ''}${
          pathNest
            ? toRouteParam(i18n.t(name))
            : toRouteParam(i18n.t(name), true)
        }`
      : null,
  };
};

export const capitalizeFirstLetter = (word: string) => {
  return `${word.charAt(0).toUpperCase()}${word?.slice(1)}`;
};

export const daysToMiliseconds = (days: number) => {
  return days * ONE_DAY;
};
