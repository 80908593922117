import { client } from '@/services/contenfulClient';
import { I18N_STORAGE_KEY } from '@/settings/constants';

const DEFAULT_LOCALE = 'pt-BR';

interface ArticleFields {
  [key: string]: any;
  title: string;
  content: string;
  breadcrumbTitle: string;
  featuredImage: {
    fields: {
      file: {
        url: string;
      };
    };
  };
}

interface InternalArticleFields extends ArticleFields {
  currentYear: string;
  slug: string;
  isPredictionArticle?: boolean;
  predictionArticleTitle?: string;
  showInfoCards?: boolean;
  infoCardsCollection?: string;
}

interface ExternalArticleFields extends ArticleFields {
  link: string;
}

interface ArticleSkeleton {
  [key: string]: any;
  contentTypeId: string;
  sys: {
    [key: string]: any;
    id: string;
  };
}

export interface InternalArticleSkeleton extends ArticleSkeleton {
  fields: InternalArticleFields;
}

export interface ExternalArticleSkeleton extends ArticleSkeleton {
  fields: ExternalArticleFields;
}

interface PredictionArticleSkeleton {
  contentTypeId: string;
  fields: {
    [key: string]: any;
    animal: string;
    articleBreadcrumb: string;
    years: string;
    description: string;
    career: string;
    love: string;
    health: string;
    conclusion: string;
  };
}

export interface InfoCardSkeleton {
  contentTypeId: string;
  fields: {
    [key: string]: any;
    title: string;
    description?: string;
    firstTopic?: string;
    firstTopicContent?: string;
    secondTopic?: string;
    secondTopicContent?: string;
    image?: {
      fields: {
        file: {
          url: string;
        };
      };
    };
  };
  sys: {
    id: string;
  };
}

interface InternalArticleFields extends ArticleFields {
  slug: string;
}

interface ExternalArticleFields extends ArticleFields {
  link: string;
}

export const getInternalArticles = async (options?: object) => {
  return await client.getEntries<InternalArticleSkeleton>({
    content_type: 'articles',
    locale: localStorage.getItem(I18N_STORAGE_KEY) ?? DEFAULT_LOCALE,
    order: ['-sys.createdAt'],
    ...options,
  });
};

export const getExternalArticles = async (options?: object) => {
  return await client.getEntries<ExternalArticleSkeleton>({
    content_type: 'externalArticle',
    locale: localStorage.getItem(I18N_STORAGE_KEY) ?? DEFAULT_LOCALE,
    order: ['-sys.createdAt'],
    ...options,
  });
};

export const getRecentArticles = async (options?: object) => {
  const NUMBER_OF_ITEMS_DEFAULT = 3;

  return await client.getEntries<InternalArticleSkeleton>({
    content_type: 'articles',
    locale: localStorage.getItem(I18N_STORAGE_KEY) ?? DEFAULT_LOCALE,
    limit: NUMBER_OF_ITEMS_DEFAULT,
    order: ['-sys.createdAt'],
    ...options,
  });
};

export const getArticleBySlug = async (slug: string) => {
  try {
    return await client.getEntries<InternalArticleSkeleton>({
      content_type: 'articles',
      locale: localStorage.getItem(I18N_STORAGE_KEY) ?? DEFAULT_LOCALE,
      'fields.slug': slug,
    });
  } catch (error) {
    console.error(error);
    return { items: [] };
  }
};

export const getPredictionArticleByAnimal = async (
  animal: string,
  options?: object,
) => {
  try {
    return await client.getEntries<PredictionArticleSkeleton>({
      content_type: 'predictionArticles',
      locale: localStorage.getItem(I18N_STORAGE_KEY) ?? DEFAULT_LOCALE,
      'fields.animal': animal,
      ...options,
    });
  } catch (error) {
    console.error(error);
    return { items: [] };
  }
};

export const getInfoCardsContent = async (collection: string) => {
  return await client.getEntries<InfoCardSkeleton>({
    content_type: 'infoCard',
    locale: localStorage.getItem(I18N_STORAGE_KEY) ?? DEFAULT_LOCALE,
    order: ['-sys.createdAt'],
    'fields.collection': collection,
  });
};
