import parse from 'html-react-parser';

import PageTitle from '@/components/atoms/PageTitle';
import { HeroTitleTypo } from '@/components/atoms/TitleTypo';
import Typography from '@/components/atoms/Typography';
import Breadcrumb from '@/components/molecules/Breadcrumb';
import { privacyPolicyContent } from '@/components/pages/PrivacyPolicy/data';
import { type PageProps } from '@/components/pages/types';
import AppTemplate from '@/components/templates/AppTemplate';
import useDocTitle from '@/hooks/useDocTitle';
import { i18n } from '@/translate/i18n';
import { v4 as uuidv4 } from 'uuid';

import './styles.scss';

const PrivacyPolicy = ({ title }: PageProps) => {
  useDocTitle(title);

  return (
    <AppTemplate>
      <PageTitle>
        <HeroTitleTypo color="white-900">
          {i18n.t('privacyPolicy.title')}
        </HeroTitleTypo>
      </PageTitle>
      <section className="privacy-policy">
        <Breadcrumb />
        <article className="privacy-policy__content">
          <Typography>{parse(i18n.t('privacyPolicy.description'))}</Typography>
          {privacyPolicyContent.map((block) => (
            <section key={uuidv4()}>
              <h1>{block.title}</h1>
              <Typography>{parse(block.sectionContent)}</Typography>
            </section>
          ))}
        </article>
      </section>
    </AppTemplate>
  );
};

export default PrivacyPolicy;
