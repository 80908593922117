import { useEffect, useState } from 'react';

import { type ArticleData } from '@/hooks/useArticleDetails/types';
import {
  getArticleBySlug,
  getPredictionArticleByAnimal,
} from '@/services/articleStore';

const useArticleDetails = (
  slug: string,
  isPredictionArticle?: boolean,
  options?: object,
) => {
  const [article, setArticles] = useState<ArticleData | null>(null);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const requestFunction = isPredictionArticle
    ? getPredictionArticleByAnimal
    : getArticleBySlug;

  useEffect(() => {
    const getArticle = () => {
      setIsLoading(true);
      requestFunction(slug, options)
        .then(({ items }) => {
          setArticles({ ...items[0]?.fields, id: items[0]?.sys?.id });
        })
        .catch((err) => {
          console.error(err.message);
          setError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    getArticle();
  }, [slug]);

  return { article, isLoading, error };
};

export default useArticleDetails;
