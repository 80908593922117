import { Link } from 'react-router-dom';

import Button from '@/components/atoms/Button';
import { TitleTypo } from '@/components/atoms/TitleTypo';
import { footerLinks } from '@/components/organisms/Footer/data';
import { i18n } from '@/translate/i18n';
import { getBtnLink } from '@/utils/helpers';

import './styles.scss';

const SectionHoroscope = () => {
  return (
    <section className="home__horoscope">
      <TitleTypo>{i18n.t('homePage.horoscope.title')}</TitleTypo>
      <img
        className="home__horoscope-image"
        alt="horoscope"
        src="/images/home/clouds-mobile.webp"
      />
      <Link
        to={getBtnLink({
          links: footerLinks,
          parent: i18n.t('footer.institutional.label'),
          prefix: i18n.t('footer.institutional.zodiac'),
        })}
      >
        <Button>{i18n.t('homePage.horoscope.ctaButton')}</Button>
      </Link>
    </section>
  );
};

export default SectionHoroscope;
