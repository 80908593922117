import { useState } from 'react';
import { Link } from 'react-router-dom';

import type {
  CustomDropdownEventListeners,
  CustomDropdownProps,
  DropdownOption,
} from '@/components/atoms/CustomDropdown/types';
import useScreenWidth from '@/hooks/useScreenWidth';

import './styles.scss';

const CustomDropdown = ({
  styleClass,
  options = [],
  label,
  handleSelect,
  isDropdown = true,
  mainPath = null,
}: CustomDropdownProps) => {
  const dropdownInitialState = !isDropdown;
  const [isOpen, setIsOpen] = useState(dropdownInitialState);
  const { isMobile } = useScreenWidth();

  const handleOpen = () => {
    setIsOpen((prevstate) => !prevstate);
  };

  const defineListeners = () => {
    const listeners: CustomDropdownEventListeners = {};
    if (!isDropdown) return listeners;

    if (isMobile) {
      listeners.onClick = handleOpen;
      return listeners;
    }

    listeners.onMouseOver = handleOpen;
    listeners.onMouseOut = handleOpen;
    return listeners;
  };

  return (
    <div className={`${styleClass} custom-dropdown`}>
      <button
        className={`${styleClass}__label custom-dropdown__label`}
        type="button"
      >
        {mainPath ? <Link to={mainPath}>{label}</Link> : label}
        <div
          className={`${styleClass}__icon-container custom-dropdown__icon-container`}
          {...defineListeners()}
        >
          <img
            className={`${styleClass}__icon custom-dropdown__icon${
              isOpen ? '--active' : ''
            }`}
            alt="chevron-icon"
          />
        </div>
      </button>
      <div
        className={`${styleClass}__options-container custom-dropdown__options-container ${
          isOpen ? '' : 'hidden active'
        }`}
        {...defineListeners()}
      >
        {options
          .filter((option) => option)
          .map((option) => {
            const dropdownOption = option as DropdownOption;
            return (
              <Link
                key={dropdownOption.id}
                className={`${styleClass}__option custom-dropdown__option`}
                to={dropdownOption.path ?? ''}
                onClick={() => {
                  if (handleSelect) {
                    handleSelect(dropdownOption.value ?? '');
                  }
                }}
              >
                {dropdownOption.name === label ? (
                  <strong>{dropdownOption.name}</strong>
                ) : (
                  dropdownOption.name
                )}
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default CustomDropdown;
