import PageTitle from '@/components/atoms/PageTitle';
import { HeroTitleTypo } from '@/components/atoms/TitleTypo';
import Breadcrumb from '@/components/molecules/Breadcrumb';
import ContactForm from '@/components/molecules/ContactForm';
import RecentArticlesSection from '@/components/organisms/RecentArticlesSection';
import { type PageProps } from '@/components/pages/types';
import AppTemplate from '@/components/templates/AppTemplate';
import useDocTitle from '@/hooks/useDocTitle';
import { i18n } from '@/translate/i18n';

import './styles.scss';

const Contact = ({ title }: PageProps) => {
  useDocTitle(title);

  return (
    <AppTemplate>
      <PageTitle>
        <HeroTitleTypo color="white-900">
          {i18n.t('contact.title')}
        </HeroTitleTypo>
      </PageTitle>
      <div className="contact">
        <Breadcrumb />
        <ContactForm />
      </div>
      <RecentArticlesSection />
    </AppTemplate>
  );
};

export default Contact;
