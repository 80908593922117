import getCountryLanguage from '@/translate/getCountryLanguage';
import { messages } from '@/translate/languages';
import { isValidLanguage } from '@/utils/helpers';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const LANG_LOCAL_STORAGE_VALUE = localStorage.getItem('i18nextLng') as string;

const userLang =
  isValidLanguage(LANG_LOCAL_STORAGE_VALUE) && LANG_LOCAL_STORAGE_VALUE;

i18n
  .use(LanguageDetector)
  .init({
    debug: false,
    defaultNS: ['translations'],
    fallbackLng: 'pt-BR',
    lng: userLang || getCountryLanguage(),
    ns: ['translations'],
    resources: messages,
  })
  .catch((err) => {
    console.error('i18n failed to initialize', err);
  });

export { i18n };
