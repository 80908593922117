import { useEffect, useState } from 'react';

import {
  type InfoCardSkeleton,
  getInfoCardsContent,
} from '@/services/articleStore';

const useInfoCards = (collection: string) => {
  const [infoCards, setInfoCards] = useState<InfoCardSkeleton[]>([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getCardsContent = () => {
      setIsLoading(true);
      getInfoCardsContent(collection)
        .then((data) => {
          setInfoCards(data.items as unknown as InfoCardSkeleton[]);
        })
        .catch((err) => {
          console.error(err.message);
          setError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    getCardsContent();
  }, []);

  return { infoCards, isLoading, error };
};

export default useInfoCards;
