import { v4 as uuidv4 } from 'uuid';

export const socialLinks = [
  {
    id: uuidv4(),
    name: 'Facebook',
    icon: '/icons/facebook.svg',
    shareUrl: 'https://www.facebook.com/sharer.php?u=',
  },
  {
    id: uuidv4(),
    name: 'Email',
    icon: '/icons/share-email.svg',
    shareUrl:
      'mailto:?subject=Veja%20este%20artigo%20&amp;body=Leia%20este%20artigo%20em%20Bazi%20by%20Marina:%20',
  },
  {
    id: uuidv4(),
    name: 'WhatsApp',
    icon: '/icons/share-whatsapp.svg',
    shareUrl: 'https://api.whatsapp.com/send?text=',
  },
  {
    id: uuidv4(),
    name: 'LinkedIn',
    icon: '/icons/linkedin.svg',
    shareUrl: 'https://www.linkedin.com/sharing/share-offsite/?url=',
  },
];
