import { RxDoubleArrowLeft, RxDoubleArrowRight } from 'react-icons/rx';
import ReactPaginate from 'react-paginate';

import { i18n } from '@/translate/i18n';

import './styles.scss';

const NUM_OF_PAGES_DEFAULT = 2;
const MINIMUM_PAGES_TO_PAGINATE = 1;

interface PaginationProps {
  currentPage: number;
  handlePageChange?: (value: number) => void;
  pageCount: number;
  setCurrentPage: (value: number) => void;
}

const Pagination = ({
  currentPage,
  handlePageChange,
  pageCount,
  setCurrentPage,
}: PaginationProps) => {
  return pageCount > MINIMUM_PAGES_TO_PAGINATE ? (
    <div className="pagination">
      <ReactPaginate
        breakLabel=""
        nextLabel={
          <>
            {i18n.t('pagination.next')} <RxDoubleArrowRight />
          </>
        }
        previousLabel={
          <>
            <RxDoubleArrowLeft />
            {i18n.t('pagination.previous')}
          </>
        }
        forcePage={currentPage - 1}
        pageCount={pageCount}
        onPageChange={({ selected }) => {
          setCurrentPage(selected + 1);

          if (handlePageChange) {
            handlePageChange(selected + 1);
          }
        }}
        pageRangeDisplayed={NUM_OF_PAGES_DEFAULT}
        activeLinkClassName="current-page"
        disabledClassName="button-disabled"
        pageLinkClassName="page-link"
        previousLinkClassName="prev-button"
        nextLinkClassName="next-button"
      />
    </div>
  ) : (
    <></>
  );
};

export default Pagination;
