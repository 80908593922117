import { Link } from 'react-router-dom';

import CustomDropdown from '@/components/atoms/CustomDropdown';
import { footerLinks, socialLinks } from '@/components/organisms/Footer/data';
import useScreenWidth from '@/hooks/useScreenWidth';
import { i18n } from '@/translate/i18n';
import { createLink } from '@/utils/formatters';

import './styles.scss';

const Footer = () => {
  const { isMobile: isDropdown } = useScreenWidth();
  // const isDropdown = screenWidth < MEDIA_QUERIES.tablet;
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <img
        src="/images/logo-footer.svg"
        alt="bazi-by-marina"
        className="footer__logo"
      />
      <div className="footer__links-container">
        {footerLinks.map((link) => (
          <CustomDropdown
            key={link.id}
            styleClass="footer-dropdown"
            options={link.listItems}
            label={link.name}
            isDropdown={isDropdown}
          />
        ))}
        <ul className="footer__contact-list">
          <p className="footer__contact-title">
            {i18n.t('footer.contact.label')}
          </p>
          {socialLinks.map((link) => (
            <li key={link.id} className="footer__social-link">
              <a href={link.url} target="_blank" rel="noreferrer">
                <span
                  className="footer__social-icon"
                  style={{ content: `url(${link.icon})` }}
                />
                {link.value}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <img
        src="/images/IFSA.webp"
        alt="IFSA-logo"
        className="footer__ifsa-logo"
      />
      <p className="footer__copy">
        {i18n.t('footer.copyright', { currentYear })}{' '}
        <a
          href="https://digitalrepublic.com.br/"
          target="_blank"
          rel="noreferrer"
        >
          Digital Republic
        </a>
        .{' '}
        <Link
          to={createLink('footer.institutional.privacyPolicy')?.path ?? ''}
          className="privacy-policy__link"
        >
          {i18n.t('footer.institutional.privacyPolicy')}
        </Link>
      </p>
    </footer>
  );
};

export default Footer;
