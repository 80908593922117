import { i18n } from '@/translate/i18n';

interface TimelineEvent {
  year: string;
  content: string;
}

export const timelineEvents: TimelineEvent[] = i18n.t('services.timeline', {
  returnObjects: true,
});

const smallScreenSlides = 1;
const mediumScreenSlides = 2;
const largeScreenSlides = 2;

export const carouselProps = {
  slidesPerView: {
    mobile: smallScreenSlides,
    tablet: mediumScreenSlides,
    desktop: largeScreenSlides,
  },
  spacing: 0,
  count: timelineEvents.length,
  indicatorType: 'dots' as const,
  indicatorColor: 'red' as const,
  slidesIds: timelineEvents.map((event) => event.year),
};
