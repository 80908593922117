import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CircularLoader from '@/components/atoms/CircularLoader';
import PageTitle from '@/components/atoms/PageTitle';
import { HeroTitleTypo } from '@/components/atoms/TitleTypo';
import ArticleCard from '@/components/molecules/ArticleCard';
import Breadcrumb from '@/components/molecules/Breadcrumb';
import Pagination from '@/components/molecules/Pagination';
import { type PageProps } from '@/components/pages/types';
import AppTemplate from '@/components/templates/AppTemplate';
import useArticles from '@/hooks/useArticles';
import useDocTitle from '@/hooks/useDocTitle';
import { i18n } from '@/translate/i18n';
import { toRouteParam } from '@/utils/formatters';
import { scrollToTop } from '@/utils/helpers';

import './styles.scss';

const FIRST_PAGE = 1;

const Press = ({ title }: PageProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialPage = searchParams.get('page');
  const [currentPage, setCurrentPage] = useState(
    Number(initialPage) || FIRST_PAGE,
  );

  const { articles, totalPages, isLoading } = useArticles(
    'external',
    currentPage,
  );

  useDocTitle(title);

  const handlePageChange = (page: number) => {
    navigate(`${toRouteParam(i18n.t('press.title'), true)}?page=${page}`);
  };

  useEffect(() => {
    scrollToTop();
  }, [currentPage]);

  return (
    <AppTemplate>
      <PageTitle>
        <HeroTitleTypo color="white-900">{i18n.t('press.title')}</HeroTitleTypo>
      </PageTitle>
      <div className="container__breadcrumb">
        <Breadcrumb />
      </div>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <div className="press">
          <div className="articles__content-wrapper">
            {articles.map((article) => {
              return (
                <ArticleCard
                  key={article.sys.id}
                  article={article}
                  external={true}
                />
              );
            })}
          </div>
          <div className="container__pagination">
            <Pagination
              currentPage={currentPage}
              pageCount={totalPages}
              setCurrentPage={setCurrentPage}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      )}
    </AppTemplate>
  );
};

export default Press;
