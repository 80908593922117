import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import router from '@/routes/routes';
import { initSentry } from '@/services/sentry';
import { checkES6Support } from '@/utils/helpers';

import './index.scss';

initSentry();
checkES6Support();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <RouterProvider router={router} />,
);
