import { i18n } from '@/translate/i18n';

import { type PrivacyPolicy } from './types';

export const privacyPolicyContent: PrivacyPolicy[] = i18n.t(
  'privacyPolicy.content',
  {
    returnObjects: true,
  },
);
