import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';

import CircularLoader from '@/components/atoms/CircularLoader';
import PageTitle from '@/components/atoms/PageTitle';
import { HeroTitleTypo } from '@/components/atoms/TitleTypo';
import Breadcrumb from '@/components/molecules/Breadcrumb';
import RecentArticlesColumn from '@/components/molecules/RecentArticlesColumn';
import InfoCardsSection from '@/components/organisms/InfoCardsSection';
import ShareArticle from '@/components/organisms/ShareArticle';
import ZodiacAnimalsPrediction from '@/components/organisms/ZodiacAnimalsPrediction';
import { type PageProps } from '@/components/pages/types';
import AppTemplate from '@/components/templates/AppTemplate';
import useArticleDetails from '@/hooks/useArticleDetails';
import useDocTitle from '@/hooks/useDocTitle';
import useScreenWidth from '@/hooks/useScreenWidth';
import { i18n } from '@/translate/i18n';
import { toRouteParam } from '@/utils/formatters';
import remarkGfm from 'remark-gfm';

import './styles.scss';

const ArticleDetails = ({ title }: PageProps) => {
  const { slug } = useParams();
  const { isMobile } = useScreenWidth();
  const { article, isLoading, error } = useArticleDetails(slug ?? '');

  useDocTitle(title);

  const shouldRenderCards =
    article?.showInfoCards && article?.infoCardsCollection;

  return (
    <AppTemplate>
      <PageTitle>
        <HeroTitleTypo color="white-900">
          {i18n.t('articles.title')}
        </HeroTitleTypo>
      </PageTitle>
      <div className="article">
        <Breadcrumb
          title={{
            label: article?.breadcrumbTitle ?? '',
            path: `${toRouteParam(i18n.t('articles.title'), true)}/${
              slug ?? ''
            }`,
          }}
        />
        {!error && (
          <div className="article__container">
            {isLoading ? (
              <CircularLoader />
            ) : (
              <div className="article__content-wrapper">
                <img
                  src={article?.featuredImage?.fields?.file?.url}
                  className="article__featured-img"
                />
                <h1 className="article__title">{article?.title}</h1>
                <div className="article__content">
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {article?.content ?? ''}
                  </ReactMarkdown>
                </div>
                {article?.isPredictionArticle && (
                  <>
                    <h2 className="article__prediction-title">
                      {article?.predictionArticleTitle}
                    </h2>
                    <ZodiacAnimalsPrediction
                      year={article?.currentYear ?? ''}
                    />
                  </>
                )}
                {shouldRenderCards && (
                  <InfoCardsSection
                    type={isMobile ? 'carousel' : 'grid'}
                    collection={article?.infoCardsCollection ?? ''}
                  />
                )}
                <ShareArticle />
              </div>
            )}
            <RecentArticlesColumn />
          </div>
        )}
      </div>
    </AppTemplate>
  );
};

export default ArticleDetails;
