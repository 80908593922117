import { Link } from 'react-router-dom';

import Button from '@/components/atoms/Button';
import { HeroTitleTypo } from '@/components/atoms/TitleTypo';
import { navLinks } from '@/components/organisms/Header/data';
import { i18n } from '@/translate/i18n';
import { getBtnLink } from '@/utils/helpers';

import './styles.scss';

const SectionBanner = () => {
  return (
    <section className="home__banner">
      <div className="home__banner-title">
        <HeroTitleTypo>{i18n.t('homePage.banner.title')}</HeroTitleTypo>
        <Link
          to={getBtnLink({
            links: navLinks,
            prefix: i18n.t('header.services.label'),
          })}
        >
          <Button>{i18n.t('homePage.banner.ctaButton')}</Button>
        </Link>
      </div>
    </section>
  );
};

export default SectionBanner;
