import PageTitle from '@/components/atoms/PageTitle';
import { HeroTitleTypo } from '@/components/atoms/TitleTypo';
import Breadcrumb from '@/components/molecules/Breadcrumb';
import ServiceCard from '@/components/molecules/ServiceCard';
import { type PageProps } from '@/components/pages/types';
import AppTemplate from '@/components/templates/AppTemplate';
import useDocTitle from '@/hooks/useDocTitle';
import { i18n } from '@/translate/i18n';

import { servicesList } from '../Home/data';
import './styles.scss';

function Services({ title }: PageProps) {
  useDocTitle(title);

  return (
    <AppTemplate>
      <div className="services">
        <PageTitle>
          <HeroTitleTypo color="white-900">
            {i18n.t('services.defaultTitle')}
          </HeroTitleTypo>
        </PageTitle>
        <Breadcrumb />
        <div className="services__container">
          {Array.isArray(servicesList) &&
            servicesList.map((service) => (
              <ServiceCard
                key={service.title}
                {...service}
                isCarousel={false}
              />
            ))}
        </div>
      </div>
    </AppTemplate>
  );
}

export default Services;
