import { type ZodiacAnimalsPageData } from '@/components/pages/ZodiacAnimals/types';
import { MEDIA_WITH_TEXT_PROPS_VALUES } from '@/settings/constants';
import { i18n } from '@/translate/i18n';

export const pageContent: ZodiacAnimalsPageData = i18n.t('zodiacAnimals', {
  returnObjects: true,
});

export const horoscope = {
  titleText: pageContent.horoscope.title,
  textContent: pageContent.horoscope.description,
  mediaPath: pageContent.horoscope.mediaPath,
  mediaType: MEDIA_WITH_TEXT_PROPS_VALUES.type.image,
  mediaPosition: MEDIA_WITH_TEXT_PROPS_VALUES.position.left,
  mediaOrientation: MEDIA_WITH_TEXT_PROPS_VALUES.orientation.portrait,
  ctaButton: pageContent.horoscope.ctaButton,
  ctaButtonColor: MEDIA_WITH_TEXT_PROPS_VALUES.buttonColor.secondary,
};
