import {
  type FooterLink,
  type FooterLinks,
} from '@/components/organisms/Footer/types';
import { createLink } from '@/utils/formatters';
import { v4 as uuidv4 } from 'uuid';

export const footerLinks: FooterLinks[] = [
  {
    ...(createLink('footer.institutional.label', false) as FooterLink),
    listItems: [
      createLink('footer.institutional.home'),
      createLink('footer.institutional.about'),
      createLink('footer.institutional.press'),
      createLink('footer.institutional.faq'),
      createLink('footer.institutional.contact'),
      createLink('footer.institutional.zodiac'),
      createLink('footer.institutional.articles'),
    ],
  },
  {
    ...(createLink('footer.services.label', false) as FooterLink),
    listItems: [
      createLink('footer.services.bazi', true, 'footer.services.label'),
      createLink('footer.services.iching', true, 'footer.services.label'),
      createLink('footer.services.fengshui', true, 'footer.services.label'),
      createLink('footer.services.ki', true, 'footer.services.label'),
      createLink(
        'footer.services.dateselection',
        true,
        'footer.services.label',
      ),
    ],
  },
];

export const socialLinks = [
  {
    id: uuidv4(),
    name: 'WhatsApp',
    icon: '/icons/whatsapp.svg',
    value: '(11) 95081-8989',
    url: 'https://api.whatsapp.com/send?phone=5511950818989&text=Ol%C3%A1!',
  },
  {
    id: uuidv4(),
    name: 'Email',
    icon: '/icons/email.svg',
    value: 'marina.caramez1@gmail.com',
    url: 'mailto:marina.caramez1@gmail.com',
  },
  {
    id: uuidv4(),
    name: 'Instagram',
    icon: '/icons/instagram.svg',
    value: '@bazi_by_marina',
    url: 'https://www.instagram.com/bazi_by_marina/',
  },
];
