import parse from 'html-react-parser';

import Typography from '@/components/atoms/Typography';
import Accordion from '@/components/molecules/Accordion';
import { type ContentTabsProps } from '@/components/molecules/ContentTabs';
import ContentTabs from '@/components/molecules/ContentTabs';
import useScreenWidth from '@/hooks/useScreenWidth';

import './styles.scss';

const KiSignatureTabs = ({ tabs }: ContentTabsProps) => {
  const { isMobile } = useScreenWidth();

  return (
    <section className="signature-tabs">
      {!isMobile && <ContentTabs tabs={tabs} />}
      {isMobile &&
        tabs.map(({ name, content, id }) => (
          <Accordion key={id} title={name}>
            <Typography>{parse(content)}</Typography>
          </Accordion>
        ))}
    </section>
  );
};

export default KiSignatureTabs;
