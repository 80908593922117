import './styles.scss';

interface PageTitleProps {
  children: React.ReactNode;
}

function PageTitle({ children }: PageTitleProps) {
  return <div className="page-title">{children}</div>;
}

export default PageTitle;
