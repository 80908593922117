import { Link } from 'react-router-dom';

import ArticleImageLink from '@/components/atoms/ArticleImageLink';
import useScreenWidth from '@/hooks/useScreenWidth';
import { i18n } from '@/translate/i18n';
import { toRouteParam } from '@/utils/formatters';

import './styles.scss';

const MAX_TEXT_LENGTH = 290;
const MAX_TEXT_LENGTH_MOBILE = 130;

interface FeaturedArticleProps {
  article: {
    fields: {
      title: string;
      content: string;
      slug: string;
      featuredImage: {
        fields: {
          file: {
            url: string;
          };
        };
      };
    };
    sys: {
      id: string;
    };
  };
}

const FeaturedArticle = ({ article }: FeaturedArticleProps) => {
  const { isDesktop } = useScreenWidth();
  const { title, featuredImage, content, slug } = article.fields;

  const imageStyle = {
    backgroundImage: `url(${featuredImage?.fields?.file?.url ?? ''})`,
  };

  const articleLink =
    `${toRouteParam(i18n.t('articles.title'), true)}/${slug ?? ''}` ?? '/';

  return (
    <div key={article.sys.id} className="featured-article">
      {
        <div
          className={`featured-article__${
            featuredImage?.fields?.file?.url ? 'image' : 'fallback-image'
          }`}
          style={imageStyle}
        >
          <ArticleImageLink to={articleLink} external={false} />
        </div>
      }
      <div>
        <Link to={articleLink}>
          <p className="featured-article__title">{title}</p>
        </Link>
        <p className="featured-article__content">
          {`${content?.slice(
            0,
            isDesktop ? MAX_TEXT_LENGTH : MAX_TEXT_LENGTH_MOBILE,
          )} [...]`}
        </p>
        <Link
          to={`${toRouteParam(i18n.t('articles.title'), true)}/${slug}`}
          className="featured-article__link"
        >
          {i18n.t('homePage.recentArticles.link')}
        </Link>
      </div>
    </div>
  );
};

export default FeaturedArticle;
