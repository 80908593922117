import Typography from '@/components/atoms/Typography';
import { socialLinks } from '@/components/organisms/ShareArticle/data';
import { i18n } from '@/translate/i18n';

import './styles.scss';

const ShareArticle = () => {
  const articleLink = window.location.href;

  return (
    <div className="share">
      <Typography styleClass="share__title">{i18n.t('share')}</Typography>
      <div className="share__social-container">
        {socialLinks
          .filter((link) => link.name !== 'Instagram')
          .map((link) => (
            <a
              key={link.id}
              href={`${link?.shareUrl}${articleLink}`}
              target="_blank"
              rel="noreferrer"
              className="share__social-link"
            >
              <span
                className="share__social-icon"
                style={{ content: `url(${link.icon})` }}
              />
            </a>
          ))}
      </div>
    </div>
  );
};

export default ShareArticle;
