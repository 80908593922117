import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Button from '@/components/atoms/Button';
import Typography from '@/components/atoms/Typography';
import { i18n } from '@/translate/i18n';
import { createLink } from '@/utils/formatters';
import { getCookies, setCookies } from '@/utils/helpers';

import './styles.scss';

const CookieWarning = () => {
  const [messageVisible, setMessageVisible] = useState(false);
  const cookiesAcceptanceKey = 'cookies';
  const cookiesAccepted = getCookies(cookiesAcceptanceKey);

  const DAYS_FOR_EXPIRATION = 365;

  const handleClick = () => {
    setCookies(cookiesAcceptanceKey, 'true', DAYS_FOR_EXPIRATION);
    setMessageVisible(false);
  };

  useEffect(() => {
    if (!cookiesAccepted) {
      setMessageVisible(true);
    }
  }, []);

  return (
    <div className={`cookie-warning ${messageVisible ? 'visible' : 'hidden'}`}>
      <div className="cookie-warning__container">
        <div className="cookie-warning__message-container">
          <Typography styleClass="cookie-warning__title">
            {i18n.t('cookies.title')}
          </Typography>
          <Typography styleClass="cookie-warning__description">
            {i18n.t('cookies.description')}{' '}
            <Link
              to={createLink('footer.institutional.privacyPolicy')?.path ?? ''}
              className="privacy-policy__link"
            >
              {i18n.t('footer.institutional.privacyPolicy')}
            </Link>
          </Typography>
        </div>
        <Button onClick={handleClick}>{i18n.t('cookies.accept')}</Button>
      </div>
    </div>
  );
};

export default CookieWarning;
