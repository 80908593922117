import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CircularLoader from '@/components/atoms/CircularLoader';
import PageTitle from '@/components/atoms/PageTitle';
import { HeroTitleTypo } from '@/components/atoms/TitleTypo';
import ArticleCard from '@/components/molecules/ArticleCard';
import Breadcrumb from '@/components/molecules/Breadcrumb';
import FeaturedArticle from '@/components/molecules/FeaturedArticle';
import Pagination from '@/components/molecules/Pagination';
import { type PageProps } from '@/components/pages/types';
import AppTemplate from '@/components/templates/AppTemplate';
import useArticles from '@/hooks/useArticles';
import useDocTitle from '@/hooks/useDocTitle';
import { type InternalArticleSkeleton } from '@/services/articleStore';
import { i18n } from '@/translate/i18n';
import { toRouteParam } from '@/utils/formatters';
import { scrollToTop } from '@/utils/helpers';

import './styles.scss';

const FIRST_PAGE = 1;

const Articles = ({ title }: PageProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialPage = searchParams.get('page');
  const [currentPage, setCurrentPage] = useState(
    Number(initialPage) || FIRST_PAGE,
  );

  const { articles, totalPages, isLoading } = useArticles(
    'internal',
    currentPage,
  );

  useDocTitle(title);

  const handlePageChange = (page: number) => {
    navigate(`${toRouteParam(i18n.t('articles.title'), true)}?page=${page}`);
  };

  useEffect(() => {
    scrollToTop();
  }, [currentPage]);

  const articleList =
    articles.length && currentPage === FIRST_PAGE
      ? articles.slice(1)
      : articles;

  return (
    <AppTemplate>
      <PageTitle>
        <HeroTitleTypo color="white-900">
          {i18n.t('articles.title')}
        </HeroTitleTypo>
      </PageTitle>
      <div className="articles">
        <Breadcrumb />

        {isLoading ? (
          <CircularLoader />
        ) : (
          <>
            {Boolean(articles.length) && currentPage === FIRST_PAGE && (
              <FeaturedArticle
                article={articles[0] as InternalArticleSkeleton}
              />
            )}
            <div className="articles__content-wrapper">
              {articleList.map((article) => {
                return <ArticleCard key={article.sys.id} article={article} />;
              })}
            </div>
            <Pagination
              currentPage={currentPage}
              pageCount={totalPages}
              setCurrentPage={setCurrentPage}
              handlePageChange={handlePageChange}
            />
          </>
        )}
      </div>
    </AppTemplate>
  );
};

export default Articles;
