import { Link } from 'react-router-dom';

import ArticleImageLink from '@/components/atoms/ArticleImageLink';
import Typography from '@/components/atoms/Typography';
import { i18n } from '@/translate/i18n';
import { toRouteParam } from '@/utils/formatters';

import './styles.scss';

const MAX_TEXT_LENGTH = 100;

interface ArticleCardProps {
  article: {
    fields: {
      title: string;
      content: string;
      slug?: string;
      link?: string;
      featuredImage: {
        fields: {
          file: {
            url: string;
          };
        };
      };
    };
    sys: {
      id: string;
    };
  };
  external?: boolean;
}

const ArticleCard = ({ article, external }: ArticleCardProps) => {
  const { title, featuredImage, content, slug, link } = article.fields;

  const imageStyle = {
    backgroundImage: `url(${featuredImage?.fields?.file?.url})`,
  };

  const articleLink =
    link ??
    `${toRouteParam(i18n.t('articles.title'), true)}/${slug ?? ''}` ??
    '/';

  return (
    <div className="article-card">
      <div className="article-card__image" style={imageStyle}>
        <ArticleImageLink to={articleLink} external={external}>
          {external && (
            <img src="/icons/ext-link-white.svg" alt="external-link-icon" />
          )}
        </ArticleImageLink>
      </div>
      <div>
        <Link to={articleLink}>
          <Typography styleClass="article-card__title">{title}</Typography>
        </Link>
        <Typography
          styleClass={`article-card__content ${external ? 'external' : ''}`}
        >
          {external ? content : `${content?.slice(0, MAX_TEXT_LENGTH)} [...]`}
        </Typography>
        {!external && (
          <Link to={articleLink} className="article-card__link">
            {i18n.t('homePage.recentArticles.link')}
          </Link>
        )}
      </div>
    </div>
  );
};

export default ArticleCard;
