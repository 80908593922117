import { useEffect, useState } from 'react';

import type {
  ExternalArticleSkeleton,
  InternalArticleSkeleton,
} from '@/services/articleStore';
import {
  getExternalArticles,
  getInternalArticles,
} from '@/services/articleStore';

type ArticleSkeleton = ExternalArticleSkeleton | InternalArticleSkeleton;

const ITEMS_PER_PAGE = 10;

const useArticles = (type: 'internal' | 'external', page: number) => {
  const [articles, setArticles] = useState<ArticleSkeleton[]>([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  const getArticleList = async () => {
    setIsLoading(true);

    const options = {
      skip: ITEMS_PER_PAGE * (page - 1),
      limit: ITEMS_PER_PAGE,
    };

    const setArticleData = (items: ArticleSkeleton[], total: number) => {
      setArticles(items);
      setTotalPages(Math.ceil(total / ITEMS_PER_PAGE));
    };

    try {
      if (type === 'external') {
        await getExternalArticles(options).then((data) => {
          const { items, total } = data;
          setArticleData(items as unknown as ArticleSkeleton[], total);
        });

        return;
      }

      await getInternalArticles(options).then((data) => {
        const { items, total } = data;
        setArticleData(items as unknown as ArticleSkeleton[], total);
      });
    } catch (err) {
      console.error('failed to load articles');
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getArticleList()
      .then()
      .catch((err) => {
        console.error(err.message);
      });
  }, [page]);

  return { articles, isLoading, totalPages, error };
};

export default useArticles;
