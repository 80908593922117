import * as Sentry from '@sentry/react';
import { useLocation, useRouteError } from 'react-router-dom';

import { type PageProps } from '@/components/pages/types';
import AppTemplate from '@/components/templates/AppTemplate';
import useDocTitle from '@/hooks/useDocTitle';
import { I18N_STORAGE_KEY } from '@/settings/constants';
import { i18n } from '@/translate/i18n';

interface Error {
  data: string;
  status: number;
}

const ErrorPage = ({ title }: PageProps) => {
  const error = useRouteError() as Error;
  const { pathname } = useLocation();

  const PAGE_NOT_FOUND = 404;
  const LANGUAGE_KEYS = {
    pt: 'pt-BR',
    en: 'en-US',
  };

  const currentLanguage = window.localStorage.getItem(
    I18N_STORAGE_KEY,
  ) as string;

  const isValidPath =
    pathname.includes(LANGUAGE_KEYS.pt) || pathname.includes(LANGUAGE_KEYS.en);

  const shouldCheck404 =
    error.status === PAGE_NOT_FOUND &&
    isValidPath &&
    !pathname.includes(currentLanguage);

  if (shouldCheck404) {
    currentLanguage === LANGUAGE_KEYS.pt
      ? window.localStorage.setItem(I18N_STORAGE_KEY, LANGUAGE_KEYS.en)
      : window.localStorage.setItem(I18N_STORAGE_KEY, LANGUAGE_KEYS.pt);

    Sentry.captureException(error);

    window.location.reload();

    return null;
  }

  useDocTitle(title);
  Sentry.captureException(error);

  return (
    <AppTemplate>
      <div id="error-page">
        <h1 className="error-page__title">{i18n.t('error.notFound.title')}</h1>
        <p className="error-page__text">
          {i18n.t('error.notFound.description')}
        </p>
        <p className="error-page__text">
          <i>{error.data}</i>
        </p>
      </div>
    </AppTemplate>
  );
};

export default ErrorPage;
