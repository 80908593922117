import React, { useContext, useMemo, useState } from 'react';

import { I18N_STORAGE_KEY, LANGUAGES } from '@/settings/constants';

import {
  LanguageContext,
  type LanguageOption,
  type TLanguage,
} from './context';

interface LanguageContextProps {
  children: React.ReactNode;
}
const LanguageProvider = ({ children }: LanguageContextProps) => {
  const currentLanguage =
    (window.localStorage.getItem(I18N_STORAGE_KEY) as TLanguage) ?? 'pt-BR';
  const selectedLanguage = LANGUAGES.find(
    (lang) => lang.value === currentLanguage,
  );

  const [language, setLanguage] = useState<LanguageOption>(
    selectedLanguage ?? LANGUAGES[0],
  );

  const contextValue = useMemo(
    () => ({ language, setLanguage }),
    [language, setLanguage],
  );

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);

export default LanguageProvider;
