import { navLinks } from '@/components/organisms/Header/data';
import { i18n } from '@/translate/i18n';
import { v4 as uuidv4 } from 'uuid';

import { type Question, type Service, type Testimonial } from './types';

const navigationServiceLinks = navLinks.find(
  (link) => link.listItems,
)?.listItems;

const rawFaqList: Question[] = i18n.t('homePage.faq.questions', {
  returnObjects: true,
});

export const faqList: Question[] = rawFaqList.map((question) => ({
  ...question,
  id: uuidv4(),
}));

const rawTestimonialList: Testimonial[] = i18n.t(
  'homePage.testimonials.content',
  { returnObjects: true },
);

export const testimonialList: Testimonial[] = rawTestimonialList.map(
  (testimonial) => ({
    ...testimonial,
    id: uuidv4(),
  }),
);

const rawServicesList: Service[] = i18n.t('services.content', {
  returnObjects: true,
});

const prefixLength = 3;

export const servicesList: Service[] = rawServicesList.map((service) => ({
  ...service,
  path:
    navigationServiceLinks?.find((navService) =>
      navService?.name.startsWith(service.title.slice(0, prefixLength)),
    )?.path ?? '/',
  id: uuidv4(),
}));
