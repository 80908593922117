import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

import Button from '@/components/atoms/Button';
import { TitleTypo } from '@/components/atoms/TitleTypo';
import Typography from '@/components/atoms/Typography';
import { navLinks } from '@/components/organisms/Header/data';
import { i18n } from '@/translate/i18n';
import { getBtnLink } from '@/utils/helpers';

import './styles.scss';

interface SectionAboutProps {
  isMobile: boolean;
}

const SectionAbout = ({ isMobile }: SectionAboutProps) => {
  return (
    <section className="home__about">
      <div className="home__about-container">
        <div className="home__about-title">
          {isMobile && (
            <TitleTypo color="white-900">
              {i18n.t('homePage.about.title')}
            </TitleTypo>
          )}
          <span className="home__about-kanji"></span>
        </div>
        <div className="home__about-content">
          {!isMobile && (
            <TitleTypo color="white-900">
              {i18n.t('homePage.about.title')}
            </TitleTypo>
          )}
          <Typography color="white-900">
            {parse(i18n.t('homePage.about.description'))}
          </Typography>
          <Link
            to={getBtnLink({
              links: navLinks,
              parent: i18n.t('header.services.label'),
              prefix: i18n.t('header.services.bazi'),
            })}
          >
            <Button size="normal" color="secondary">
              {i18n.t('homePage.about.ctaButton')}
            </Button>
          </Link>
        </div>
      </div>
      {!isMobile && (
        <img
          className="home__about-image"
          alt="telhado-chines"
          src="/images/home/telhado.webp"
        />
      )}
    </section>
  );
};

export default SectionAbout;
