import { Link } from 'react-router-dom';

import Button from '@/components/atoms/Button';
import { SubtitleTypo } from '@/components/atoms/TitleTypo';
import { type ZodiacCardProps } from '@/components/molecules/ZodiacCard/types';
import { i18n } from '@/translate/i18n';
import { toRouteParam } from '@/utils/formatters';

import './styles.scss';

function ZodiacCard({
  isCarousel,
  minimalVersion,
  zodiacAnimal,
}: ZodiacCardProps) {
  const { title, icon, years, strengths, weaknesses, ctaButton, goTo } =
    zodiacAnimal;

  const adjectiveListToString = (adjectiveList: string[]) => {
    return adjectiveList
      .map((adjective, index) =>
        !index
          ? adjective.charAt(0).toUpperCase() + adjective.slice(1)
          : adjective.toLowerCase(),
      )
      .join(', ');
  };

  return (
    <article
      className={`${
        isCarousel ? 'carousel-view' : 'grid-view'
      } zodiac-card keen-slider__slide ${minimalVersion ? 'minimal' : ''}`}
    >
      <img className="zodiac-card__icon" src={icon} alt={`${title}-icon`} />
      <SubtitleTypo>{title}</SubtitleTypo>
      <div className={`zodiac-card__years ${minimalVersion ? 'minimal' : ''}`}>
        {years.map((year) => (
          <span key={year}>{year}</span>
        ))}
      </div>
      {!minimalVersion && (
        <>
          <p className="zodiac-card__adjective">
            <span>{`${strengths.title}:`}</span>
            {` ${adjectiveListToString(strengths.list)}.`}
          </p>
          <p className="zodiac-card__adjective">
            <span>{`${weaknesses.title}:`}</span>
            {` ${adjectiveListToString(weaknesses.list)}.`}
          </p>
          <Link
            to={`${toRouteParam(
              i18n.t('footer.institutional.chineseAnimals'),
              true,
            )}/${goTo}`}
            className="services-card__link"
          >
            <Button>{ctaButton}</Button>
          </Link>
        </>
      )}
    </article>
  );
}

export default ZodiacCard;
