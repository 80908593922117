import { useEffect, useState } from 'react';

import { DELAY, MEDIA_QUERIES } from '@/settings/constants';
import { debounce } from '@/utils/helpers';

const useScreenWidth = () => {
  const currentWidth = window.innerWidth;
  const [width, setWidth] = useState(currentWidth);
  const [screenSize, setScreenSize] = useState({
    isMobile: currentWidth < MEDIA_QUERIES.tablet,
    isTablet:
      currentWidth >= MEDIA_QUERIES.tablet &&
      currentWidth < MEDIA_QUERIES.desktop,
    isDesktop: currentWidth >= MEDIA_QUERIES.desktop,
  });
  const [isResizing, setIsResizing] = useState(false);

  const handleResize = () => {
    setIsResizing(true);

    debounce(() => {
      const updatedWidth = window.innerWidth;
      setWidth(updatedWidth);
      setIsResizing(false);

      const currentSize = {
        isMobile: updatedWidth < MEDIA_QUERIES.tablet,
        isTablet:
          updatedWidth >= MEDIA_QUERIES.tablet &&
          updatedWidth < MEDIA_QUERIES.desktop,
        isDesktop: updatedWidth >= MEDIA_QUERIES.desktop,
      };

      setScreenSize(currentSize);
    }, DELAY.short)();
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { ...screenSize, width, isResizing };
};

export default useScreenWidth;
