import { TitleTypo } from '@/components/atoms/TitleTypo';
import { type ServiceTitleProps } from '@/components/molecules/ServiceTitle/types';

import './styles.scss';

const ServiceTitle = ({ title, iconPath }: ServiceTitleProps) => {
  return (
    <div className="service-pages__service-title">
      <TitleTypo>{title}</TitleTypo>
      {iconPath && (
        <img
          className="service-pages__service-icon"
          src={iconPath}
          alt={title ?? 'service-icon'}
        />
      )}
    </div>
  );
};

export default ServiceTitle;
