import parse from 'html-react-parser';

import { TitleTypo } from '@/components/atoms/TitleTypo';
import Typography from '@/components/atoms/Typography';
import Carrousel from '@/components/organisms/Carrousel';
import { carouselProps } from '@/components/organisms/SectionTestimonials/data';
import { testimonialList } from '@/components/pages/Home/data';
import { type Testimonial } from '@/components/pages/Home/types';
import useScreenWidth from '@/hooks/useScreenWidth';
import { i18n } from '@/translate/i18n';

import './styles.scss';

const SingleTestimonial = ({ description, author }: Testimonial) => {
  return (
    <article className="home__testimonial-card keen-slider__slide">
      <span className="home__testimonial-logo" />
      <Typography color="white-900">{parse(description)}</Typography>
      <Typography color="white-900">{parse(author)}</Typography>
    </article>
  );
};

const SectionTestimonials = () => {
  const { isResizing } = useScreenWidth();

  if (isResizing) return null;

  return (
    <section className="home__testimonials">
      <TitleTypo color="white-900">
        {i18n.t('homePage.testimonials.title')}
      </TitleTypo>
      <Carrousel {...carouselProps} adaptiveHeight={true}>
        {Array.isArray(testimonialList) &&
          testimonialList.map((testimonial) => (
            <SingleTestimonial key={testimonial.id} {...testimonial} />
          ))}
      </Carrousel>
    </section>
  );
};

export default SectionTestimonials;
