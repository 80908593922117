import parse from 'html-react-parser';

import PageTitle from '@/components/atoms/PageTitle';
import { HeroTitleTypo } from '@/components/atoms/TitleTypo';
import Typography from '@/components/atoms/Typography';
import Breadcrumb from '@/components/molecules/Breadcrumb';
import SectionAnimals from '@/components/organisms/SectionAnimals';
import { pageContent } from '@/components/pages/ChineseAnimals/data';
import { type PageProps } from '@/components/pages/types';
import AppTemplate from '@/components/templates/AppTemplate';
import useDocTitle from '@/hooks/useDocTitle';
import useScreenWidth from '@/hooks/useScreenWidth';

import './styles.scss';

const ChineseAnimals = ({ title }: PageProps) => {
  const { isMobile, isTablet } = useScreenWidth();

  useDocTitle(title);

  return (
    <AppTemplate>
      <div className="container-page">
        <PageTitle>
          <HeroTitleTypo color="white-900">{pageContent.title}</HeroTitleTypo>
        </PageTitle>
        <Breadcrumb />

        <Typography styleClass="breadcrumb">
          {parse(pageContent.content)}
        </Typography>
        <div className="chinese-animals__container">
          <SectionAnimals
            type={isMobile || isTablet ? 'carousel' : 'grid'}
            bgColor="white"
          />
        </div>
      </div>
    </AppTemplate>
  );
};

export default ChineseAnimals;
