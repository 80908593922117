import SectionWhoIsMarina from '@/components/organisms/HomeSectionMarina';
import RecentArticlesSection from '@/components/organisms/RecentArticlesSection';
import SectionAbout from '@/components/organisms/SectionAbout';
import SectionBanner from '@/components/organisms/SectionBanner';
import SectionFaq from '@/components/organisms/SectionFaq';
import SectionHoroscope from '@/components/organisms/SectionHoroscope';
import SectionInstagram from '@/components/organisms/SectionInstagram';
import SectionServices from '@/components/organisms/SectionServices';
import SectionTestimonials from '@/components/organisms/SectionTestimonials';
import { type PageProps } from '@/components/pages/types';
import useDocTitle from '@/hooks/useDocTitle';
import useScreenWidth from '@/hooks/useScreenWidth';
import { i18n } from '@/translate/i18n';

import './styles.scss';

const Home = ({ title }: PageProps) => {
  const { isMobile } = useScreenWidth();

  useDocTitle(title);

  return (
    <div className="home">
      <SectionBanner />
      <SectionAbout isMobile={isMobile} />
      <SectionServices title={i18n.t('services.defaultTitle')} />
      <SectionHoroscope />
      <SectionTestimonials />
      <SectionWhoIsMarina />
      <RecentArticlesSection />
      <SectionFaq />
      <SectionInstagram />
    </div>
  );
};

export default Home;
