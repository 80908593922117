import { TitleTypo } from '@/components/atoms/TitleTypo';
import BulletText from '@/components/molecules/BulletText';
import { type IconPath } from '@/components/molecules/ServiceTitle/types';
import { v4 as uuidv4 } from 'uuid';

import './styles.scss';

interface BulletTextProps {
  title: string;
  bullets: Array<[IconPath, string]>;
}

const SectionBulletText = ({ title, bullets }: BulletTextProps) => {
  return (
    <section className="bullet-text">
      <TitleTypo>{title}</TitleTypo>
      <ul className="bullet-text__container">
        {bullets.map((bullet) => (
          <BulletText key={uuidv4()} bullet={bullet} />
        ))}
      </ul>
    </section>
  );
};

export default SectionBulletText;
