import './styles.scss';

function CircularLoader() {
  return (
    <div className="circular-loader">
      <span />
    </div>
  );
}

export default CircularLoader;
