import PageTitle from '@/components/atoms/PageTitle';
import { HeroTitleTypo } from '@/components/atoms/TitleTypo';
import Breadcrumb from '@/components/molecules/Breadcrumb';
import SectionMarina from '@/components/organisms/AboutSectionMarina';
import SectionMission from '@/components/organisms/SectionMission';
import SectionServices from '@/components/organisms/SectionServices';
import SectionTimeline from '@/components/organisms/SectionTimeline';
import { type PageProps } from '@/components/pages/types';
import AppTemplate from '@/components/templates/AppTemplate';
import useDocTitle from '@/hooks/useDocTitle';
import useScreenWidth from '@/hooks/useScreenWidth';
import { i18n } from '@/translate/i18n';

import './styles.scss';

const About = ({ title }: PageProps) => {
  const { isMobile, isTablet } = useScreenWidth();

  useDocTitle(title);

  return (
    <AppTemplate>
      <div className="about">
        <PageTitle>
          <HeroTitleTypo color="white-900">
            {i18n.t('aboutPage.title')}
          </HeroTitleTypo>
        </PageTitle>
        <Breadcrumb />
        <SectionMarina />
        <SectionMission />
        <SectionTimeline isMobile={isMobile} isTablet={isTablet} />
        <SectionServices title={i18n.t('services.defaultTitle')} />
      </div>
    </AppTemplate>
  );
};

export default About;
