import parse from 'html-react-parser';
import { useState } from 'react';

import Typography from '@/components/atoms/Typography';

import './styles.scss';

interface ContentTab {
  name: string;
  content: string;
  id: string;
}

export interface ContentTabsProps {
  tabs: ContentTab[];
}

const ContentTabs = ({ tabs }: ContentTabsProps) => {
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const handleSelect = (id: string) => {
    const selected = tabs.find((tab) => tab.id === id);
    if (selected) setCurrentTab(selected);
  };

  return (
    <article className="tabs-container">
      <div className="tabs-container__tabs">
        {tabs.map(({ name, id }) => (
          <div
            key={id}
            className={`tabs-container__title ${
              id === currentTab.id ? 'active' : ''
            }`}
            onClick={() => {
              handleSelect(id);
            }}
          >
            <Typography>{parse(name)}</Typography>
          </div>
        ))}
      </div>
      <div className="tabs-container__content-container">
        {tabs.map(({ content, id }) => (
          <div
            key={id}
            className={`tabs-container__content ${
              id === currentTab.id ? 'active' : ''
            }`}
          >
            <Typography>{parse(content)}</Typography>
          </div>
        ))}
      </div>
    </article>
  );
};

export default ContentTabs;
