import { createContext } from 'react';

import { LANGUAGES } from '@/settings/constants';

export type TLanguage = 'en-US' | 'pt-BR';

export interface LanguageOption {
  id: string;
  name: string;
  value: TLanguage;
}

interface LanguageContextData {
  language: LanguageOption;
  setLanguage: (language: LanguageOption) => void;
}

export const [portuguese] = LANGUAGES;

export const LanguageContext = createContext<LanguageContextData>({
  language: portuguese,
  setLanguage: () => {},
});
