import {
  MEDIA_QUERIES,
  MEDIA_WITH_TEXT_PROPS_VALUES,
} from '@/settings/constants';
import { i18n } from '@/translate/i18n';

import { type FengShuiPageData } from './types';

export const pageContent: FengShuiPageData = i18n.t('servicePages.fengShui', {
  returnObjects: true,
});

const isMobile = window.innerWidth < MEDIA_QUERIES.tablet;

export const understandingFengShui = {
  titleText: pageContent.understandingFengShui.title,
  iconPath: pageContent.understandingFengShui.kanjiPath,
  textContent: pageContent.understandingFengShui.description,
  mediaPath: pageContent.understandingFengShui.mediaPath,
  mediaType: MEDIA_WITH_TEXT_PROPS_VALUES.type.video,
};

export const whatIsKi = {
  titleText: pageContent.whatIsKi.title,
  textContent: pageContent.whatIsKi.description,
  mediaPath: pageContent.whatIsKi.mediaPath,
  mediaType: MEDIA_WITH_TEXT_PROPS_VALUES.type.image,
  mediaOrientation: MEDIA_WITH_TEXT_PROPS_VALUES.orientation.portrait,
  mediaPosition: MEDIA_WITH_TEXT_PROPS_VALUES.position.left,
};

export const whatToExpect = {
  titleText: pageContent.whatToExpect.title,
  textContent: pageContent.whatToExpect.description,
  mediaPath: pageContent.whatToExpect.mediaPath,
  mediaType: MEDIA_WITH_TEXT_PROPS_VALUES.type.image,
  mediaOrientation: isMobile
    ? MEDIA_WITH_TEXT_PROPS_VALUES.orientation.landscape
    : MEDIA_WITH_TEXT_PROPS_VALUES.orientation.portrait,
  mediaPosition: MEDIA_WITH_TEXT_PROPS_VALUES.position.right,
  ctaButton: pageContent.whatToExpect.ctaButton,
  ctaButtonColor: MEDIA_WITH_TEXT_PROPS_VALUES.buttonColor.secondary,
  ctaButtonPosition: MEDIA_WITH_TEXT_PROPS_VALUES.buttonPosition.middle,
};

export const formPageInfo = {
  formTitle: pageContent.formTitle,
  defaultService: i18n.t('servicePages.form.options.fengShui'),
};
