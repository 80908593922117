import { useRef, useState } from 'react';

import './style.scss';

interface VideoProps {
  source: string;
}

export const Video = ({ source }: VideoProps) => {
  const [wasPlayed, setWasPlayed] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlay = async () => {
    try {
      if (videoRef.current?.paused) {
        setIsPaused(false);
        !wasPlayed && setWasPlayed(true);
      } else {
        setIsPaused(true);
      }
    } catch (error) {
      console.error('video:', error);
    }
  };

  return (
    <div className="video-container">
      <video
        controls
        ref={videoRef}
        poster="/images/thumbnail.webp"
        onClick={() => {
          void handlePlay();
        }}
      >
        <source src={source} type="video/mp4" />
      </video>
      <div className={wasPlayed && isPaused ? 'play-action' : ''}>
        <img src="/icons/play.png" alt="play" />
      </div>
    </div>
  );
};
