import CookieWarning from '@/components/organisms/CookieWarning';
import Footer from '@/components/organisms/Footer';
import Header from '@/components/organisms/Header';
import LanguageProvider from '@/context/LanguageContext';
import useScrollToTop from '@/hooks/useScrollToTop';

import type { AppTemplateProps } from './types';

const AppTemplate = ({ children }: AppTemplateProps) => {
  useScrollToTop();

  return (
    <>
      <LanguageProvider>
        <Header />
        <main>{children}</main>
        <Footer />
        <CookieWarning />
      </LanguageProvider>
    </>
  );
};

export default AppTemplate;
